export const alertInitialState = { showAlert: false, alertMessage: "", error: false };

export const SHOW_SUCCESS_ALERT = "SHOW_SUCCESS_ALERT";
export const SHOW_ERROR_ALERT = "SHOW_ERROR_ALERT";
export const HIDE_ALERT = "HIDE_ALERT";

export const alertReducer = (state, { type, message }) => {
	switch (type) {
		case HIDE_ALERT:
			return { ...state, error: false, showAlert: false, alertMessage: "" };
		case SHOW_ERROR_ALERT:
			return { ...state, error: true, showAlert: true, alertMessage: message || "" };
		case SHOW_SUCCESS_ALERT:
			return { ...state, error: false, showAlert: true, alertMessage: message || "" };
		default:
			return state;
	}
};
