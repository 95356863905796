export const localeInitialState = { locale: "en_US", country: "us" };

export const UPDATE_LOCALE = "UPDATE_LOCALE";

export const localeReducer = (state, { type, payload }) => {
	switch (type) {
		case UPDATE_LOCALE:
			return { ...state, locale: payload?.locale, country: payload?.country };
		default:
			return state;
	}
};
