import "./Loading.css";

export const Loading = ({ show = false , type = ""}) => {
  return (
    <>
      {show && <div className={`d-flex justify-content-center align-items-center ${type !== "" ? 'vh-100': 'my-5'} `}>
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>}
    </>
  );
};
