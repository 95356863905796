import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { ReactComponent as Logout } from "../../appSVG/header/logout.svg";
import { ReactComponent as MyProductsLogo } from "../../appSVG/header/products.svg";
import { ReactComponent as Profile } from "../../appSVG/header/profile.svg";
import ArrowBottomSVG from "../../assets/img/Arrow-bottom.svg";
import USAFlag from "../../assets/img/flags/unitedStates-512.png";
import SonyLogo from "../../appSVG/sonyLogo.png";
import i18n from "../../i18n";
import { AppContext } from "../../store/AppProvider";
import {
  domainLanguageCombo,
  languageOptions,
} from "../../utils/dataConstants";
import {
  convertLangToParams,
  currentDomain,
  getRandomString,
  handleSignIn,
  openMyProfile,
  removeHash,
} from "../../utils/utils";
import { MDCIM_OAUTH_URL, app_client_id } from "../../config";
import './Header.css'
import { LogoutModal } from "../components";

export const Header = () => {
  const [language, setLanguage] = useState("US");
  const [flag, setFlag] = useState(USAFlag);
  const [showLogout, setShowLogOut] = useState(false);
  const [langDropOption, setLangDropOption] = useState(languageOptions);
  const urlParams = new URLSearchParams(window.location.search).toString()

  const {
    loggedInUser: { first_name },
  } = useContext(AppContext);
  const isLoggedIn = Boolean(first_name);

  const { t } = useTranslation();
  const { resolvedLanguage } = i18n;
  const { locale } = useParams();

  useEffect(() => {
    const { lang, flag, country, languageCode } = langDropOption[resolvedLanguage];
    setLanguage(lang);
    setFlag(flag);
    document.documentElement.lang = languageCode;
    if (isLoggedIn) {
      const obj = {}
      for (let i in languageOptions) {
        if (languageOptions[i]['country'] === country) {
          obj[i] = languageOptions[i]
        }
      }
      setLangDropOption(obj)
    } else {
      setLangDropOption(languageOptions)
    }
  }, [resolvedLanguage]);

  const handleChangeLanguage = (nextLangLocale, event) => {
    event?.preventDefault();
    const nxtLocale = convertLangToParams(nextLangLocale);
    if (locale === nxtLocale) return;
    const { pathname, origin } = window.location;
    const domain = currentDomain();
    if (domain) {
      let newPath = "";
      if (domainLanguageCombo[domain].includes(nextLangLocale)) {
        //don't switch domain
        newPath = locale
          ? pathname.replace(locale, nxtLocale)
          : `/${nxtLocale}/${pathname.split("/")[1]}`;
      } else {
        const url = origin.includes(".ca")
          ? origin.replace(".ca", ".com")
          : origin.replace(".com", ".ca");
        const path = locale ? pathname.split("/")[2] : pathname.split("/")[1];
        if (path === "registered_products" && isLoggedIn) {
          //not handling queryParameter as it wont exist for logged in User
          handleSignIn(null, url, nxtLocale);
          return;
        } else {
          newPath = !!path ? `${url}/${nxtLocale}/${path}` : `${url}/${nxtLocale}`
        }
      }
      const newURL = Boolean(urlParams) ? `${newPath}?${urlParams}` : newPath
      window.location.replace(newURL);
    }
  };

  const handleContextMenu = (nextLangLocale, event) => {
    event?.preventDefault();
    const nxtLocale = convertLangToParams(nextLangLocale);
    if (locale === nxtLocale) return;
    const { pathname, origin } = window.location;
    const domain = currentDomain();
    if (domain) {
      let newPath = "";
      if (domainLanguageCombo[domain].includes(nextLangLocale)) {
        //don't switch domain
        newPath = locale
          ? pathname.replace(locale, nxtLocale)
          : `/${nxtLocale}/${pathname.split("/")[1]}`;
      } else {
        const url = origin.includes(".ca")
          ? origin.replace(".ca", ".com")
          : origin.replace(".com", ".ca");
        const path = locale ? pathname.split("/")[2] : pathname.split("/")[1];
        if (path === 'registered_products') {
          newPath = `${url}/${nxtLocale}/callback`;
          const queryParams = `/signin?client_id=${app_client_id}&scope=openid+users&redirect_uri=${newPath}&state=${getRandomString(40)}&nonce=${getRandomString(40)}`;
          const oauthURL = `${MDCIM_OAUTH_URL}${queryParams}`
          window.open(oauthURL, "_blank");
          return;
        } else {
          newPath = !!path ? `${url}/${nxtLocale}/${path}` : `${url}/${nxtLocale}`
        }
      }
      const newURL = Boolean(urlParams) ? `${newPath}?${urlParams}` : newPath
      window.open(newURL, "_blank");
    }
  };

  const onCancelLogOut = () => {
    setShowLogOut(false);
  };


  return (
    <>
      <a href="#main" className="skip-to-main-content-link">{t('common.Skip to main content')}</a>
      <header id="header" className="fixed-top d-flex align-items-center">
        <div className="container d-flex align-items-center justify-content-between">
          <div className="logo">
            <h1>
              <a href="/">
                <img src={SonyLogo} className="sonyLogo" alt="Sony Logo" />
              </a>
            </h1>
          </div>
          <div className="d-flex">
            <nav id="navbar" className="navbar">
              <ul>
                <li className="dropdown">
                  <a href="#" onClick={(e) => removeHash(e)}>
                    <div
                      id="languageDropdown"
                      className="d-flex align-items-center"
                    >
                      <img src={flag} alt={`flag ${flag?.split('.')[0]}`} />
                      <span>{language}</span>
                    </div>
                    <div>
                      <img
                        src={ArrowBottomSVG}
                        alt=""
                        className="arrow-bottom"
                      />
                    </div>
                  </a>
                  <ul className="myList">
                    {Object.keys(langDropOption)?.map((key) => {
                      const { label, lang, flag } = langDropOption[key];
                      return (
                        <li
                          key={key}
                          onClick={(e) => handleChangeLanguage(key, e)}
                          onContextMenu={(e) => handleContextMenu(key, e)}
                        >
                          <a href="#" data-lang={lang} onClick={(e) => removeHash(e)}>
                            <img src={flag} alt={`flag ${label}`} />
                            <span>{label}</span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              </ul>
              <button className="d-none">{t("header.login")}</button>
            </nav>
            {isLoggedIn && (
              <nav id="navbarHome" className="navbar">
                <ul>
                  <li className="dropdown">
                    <a href="#" id="myProductLink" onClick={(e) => removeHash(e)}>
                      <MyProductsLogo />
                      <div className="d-flex align-items-center">
                        <span>{t("header.myProducts")}</span>
                        <img
                          src={ArrowBottomSVG}
                          alt="arrow bottom"
                          className="arrow-bottom"
                        />
                      </div>
                    </a>
                    <ul>
                      <li>
                        <a
                          href="#"
                          data-bs-target="#myProfile"
                          onClick={(e) => { removeHash(e); openMyProfile() }}
                        >
                          <Profile />
                          <span>{t("header.myProfile")}</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href="#"
                          data-bs-target="#logoutDemo"
                          onClick={(e) => { removeHash(e); setShowLogOut(true) }}
                        >
                          <Logout />
                          <span>{t("header.logout")}</span>
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
            )}
            {!isLoggedIn && (
              <div className="navbar">
                <button
                  className={language === 'ES' ? 'login-spanish' : 'login-button'}
                  onClick={(e) => handleSignIn(e, null, locale)}
                >
                  {t("header.login")}
                </button>
              </div>
            )}

            <LogoutModal
              show={showLogout}
              closeLogOut={onCancelLogOut}
              locale={locale}
            />
          </div>
          {/* End NavBar */}
        </div>
      </header>
    </>
  );
};