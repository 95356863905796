export const setSessionStorage = (key, value) => {
    sessionStorage.setItem(key, value);
  };
  

  export const getSessionStorage = (key) => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue ? storedValue : null;
  };
  

  export const deleteFromSessionStorage = (key) => {
    sessionStorage.removeItem(key);
  };
  