import { ErrorBoundary } from "react-error-boundary"
import { Outlet } from "react-router-dom";

export const AppErrorBoundary = (props) => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallBack} onError={logErrorToService}>
      <Outlet />
    </ErrorBoundary>
  )
}

export function ErrorFallBack({ error }) {
  return (
    <div role="alert" style={{ margin: "25px" }}>
      <p>Something Went Wrong!!!</p>
      <p>{error.message}</p>
    </div>
  );
}

export function logErrorToService(error, info) {
  // Use your preferred error logging service
  console.error("Caught an error:", error, info);
}
