
import React from 'react'

const ProductsPagination = ({ itemsPerPage, totalItems, currentPage, paginate, shownPageNumbers = 3 }) => {
    const numPages = Array(Math.ceil(totalItems / itemsPerPage)).fill().map((v, i) => ++i)
    let sliceIndex = 1

    if (numPages?.length > shownPageNumbers) {
        sliceIndex = currentPage + shownPageNumbers < numPages?.length
            ? currentPage
            : numPages?.length - shownPageNumbers
    }
    const pageNumbers = numPages?.slice(sliceIndex - 1, sliceIndex + 3)
    const lastPage = Math.ceil(totalItems / itemsPerPage)

    const previousPage = () => {
        if (currentPage !== 1) {
            paginate(currentPage - 1);
        }
    };

    const nextPage = () => {
        if (currentPage !== Math.ceil(totalItems / itemsPerPage)) {
            paginate(currentPage + 1);
        }
    };

    return (
        <div className="d-flex block__pagination justify-content-center">
            <nav>
                <ul className="pagination">
                    <li className="page-item" onClick={() => previousPage()}>
                        {currentPage !== 1 &&
                            <span className="page-link" aria-label="Previous">
                                <span aria-hidden="true">&lsaquo;</span>
                            </span>
                        }
                    </li>
                    {pageNumbers.map((num, i) =>
                        <li key={i + Math.random()} className={`page-item ${currentPage === num && 'active'}`}
                            onClick={() => paginate(num)}>
                            <span className="page-link" >{num}</span>
                        </li>)
                    }
                    <li className="page-item" onClick={() => nextPage()}>
                        {currentPage !== lastPage &&
                            <span className="page-link" aria-label="Next">
                                <span>&rsaquo;</span>
                            </span>
                        }
                    </li>
                </ul>
            </nav>
        </div>
    )
}

export default ProductsPagination