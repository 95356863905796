import { useContext } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";
import {
  Header,
  Footer,
  AlertNotifier,
  ErrorFallBack,
  logErrorToService,
  BackdropLoader,
  PageNotFound,
} from "./components/components.js";
import {
  MyProducts,
  Login,
  Callback,
  Home,
  RegisterConfirmation,
} from "./pages/pages.js";
import { AppContext, AppProvider } from "./store/AppProvider";
import { domainLanguageCombo } from "./utils/dataConstants.js";
import { convertParamsToLang, currentDomain } from "./utils/utils.js";
import { UserPageProvider } from "./sprUtilityHooks/sprlocationHookcontext.js";
// Vendor CSS Files
import "./App.css";
import "./assets/vendor/animate.css/animate.min.css";
import "./assets/vendor/bootstrap/css/bootstrap.min.css";
import "./assets/vendor/spri-icons/style.css";
import "./assets/vendor/swiper/swiper-bundle.min.css";
// Template Main CSS File
import "./assets/css/bootstrap_variables.css";
import "./assets/css/footer-styles.css";
import "./assets/css/form-group.css";
import "./assets/css/modal.css";
import "./assets/css/my-products.css";
import "./assets/css/pagination.css";
import "./assets/css/style.css";
//js
import "./assets/vendor/bootstrap/js/bootstrap.bundle.min.js";
import "./assets/vendor/purecounter/purecounter_vanilla.js";
import "./assets/vendor/swiper/swiper-bundle.min.js";

function LocaleCheck() {
  const { locale } = useParams();
  const urlParams = new URLSearchParams(window.location.search).toString();
  if (
    !locale ||
    domainLanguageCombo[currentDomain()]?.includes(convertParamsToLang(locale))
  )
    return <Outlet />;
  else
    window.location.replace(
      `/${window.location.pathname.split("/")[2] || ""}?${urlParams}`
    );
}

function App() {
  return (
    <AppProvider>
      <PrimaryApp />
    </AppProvider>
  );
}

function AppLayout() {
  return (
    <>
      <Header />
      <ErrorBoundary
        FallbackComponent={ErrorFallBack}
        onError={logErrorToService}
      >
        <Outlet />
      </ErrorBoundary>
      <Footer />
    </>
  );
}

const ProtectedRoute = ({ user, redirectPath = "/login", children }) => {
  if (!user) {
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
};

const PublicRoute = ({
  user,
  redirectPath = "/registered_products",
  children,
}) => {
  if (user) {
    return <Navigate to={redirectPath} replace />;
  }
  return children ? children : <Outlet />;
};

function PrimaryApp() {
  const {
    loggedInUser: { first_name },
    showBLoader: { showLoader, type, size },
  } = useContext(AppContext);
  const isLoggedIn = Boolean(first_name);
  return (
    <>
      <AlertNotifier />
      <BackdropLoader show={showLoader} size={size} type={type} />
      <UserPageProvider>
        <Routes>
          <Route element={<AppLayout />}>
            <Route element={<LocaleCheck />}>
              <Route element={<PublicRoute user={isLoggedIn} />}>
                <Route path="/:locale?/" element={<Home />} />
                <Route path="/:locale?/registration" element={<Home />} />
                <Route path="/:locale?/login" element={<Login />} />
              </Route>
              <Route
                path="/:locale?/registration_confirmation"
                element={<RegisterConfirmation />}
              />
              <Route element={<ProtectedRoute user={isLoggedIn} />}>
                <Route
                  path="/:locale?/registered_products"
                  element={<MyProducts />}
                />
              </Route>
            </Route>
          </Route>
          <Route element={<LocaleCheck />}>
            <Route path="/:locale?/callback" element={<Callback />} />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </UserPageProvider>
    </>
  );
}

export default App;
