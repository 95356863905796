import { setSessionStorage } from "../../service/SessionStorageService";
import { stateCode } from "../../utils/Constants";

export const stateCodeInitialState = { state_code: sessionStorage.getItem('stateCode') || null }

export const UPDATE_STATE_CODE = "UPDATE_STATE_CODE";

export const stateCodeReducer = (state, { type, payload }) => {
    switch (type) {
        case UPDATE_STATE_CODE: {
            setSessionStorage(stateCode, payload?.state_code)
            return { ...state, state_code: payload?.state_code }
        } default:
            return state;
    }
}