import React, { useEffect } from "react";
import { HeroIcons, SvgIcons } from "../../utils/dataConstants";
import { handleCarouselItems, handleSignIn, handleSignUp } from "../../utils/utils";
import CarouselView from "./carouselview";
import CarouselMobileView from "./carousemobileview";
import LoginHeader from "./loginHeader";
import ServiceComp from "./serviceComp";
import { useParams } from "react-router";
import { deleteFromSessionStorage } from "../../service/SessionStorageService";

export const Login = () => {
  window.history.replaceState({}, document.title, window.location.pathname);
  const { locale } = useParams()

  useEffect(() => {
    deleteFromSessionStorage('registeredProduct');
    handleCarouselItems();
  }, []);

  return (
    <div id="login">
      <section id="hero">
        <LoginHeader signIn={(e) => { handleSignIn(e, null, locale) }} signUp={(e) => { handleSignUp(e, null, locale) }} />
        <CarouselView {...HeroIcons} />
        <CarouselMobileView {...HeroIcons} />
      </section>
      <main id="main" style={{ marginTop: 0 }}>
        <ServiceComp {...SvgIcons} />
      </main>
      <hr className="separator" />
    </div>
  );
}
