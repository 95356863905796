import { useContext, useEffect, useState } from "react";
import { getFooter } from "../../service/ProductService";
import i18n from "../../i18n";
import privacyChoiceIcon from '../../appSVG/checkPrivacyChoices.png';
import { AppContext } from "../../store/AppProvider";
import { SHOW_ERROR_ALERT } from "../../store/reducer/AlertReducer";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { setShowAlert } = useContext(AppContext);
  const { resolvedLanguage } = i18n;
  const [data, setData] = useState(null);
  const [scriptLinks, setScriptLinks] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const localLang = resolvedLanguage === "es_US" ? "en_US" : resolvedLanguage
    updateContent(localLang);
  }, [resolvedLanguage]);


  useEffect(() => {//handles execution of external JS from Footer
    const loadScript = (src) => {
        const script = document.createElement('script');
        script.src = src;
        script.async = true;
        document.body.appendChild(script);
    };

    scriptLinks.forEach((link) => {
        loadScript(link);
    });

  }, [scriptLinks]);

  async function updateContent(locale) {
    try {
      const data = await getFooter(locale);
      let parser = new DOMParser();
      let doc = parser.parseFromString(data, 'text/html');
      let scriptTags = doc.getElementsByTagName('script');
      let temp = [];

      for (var i = 0; i < scriptTags.length; i++) {
          temp.push(scriptTags[i].src);
      }

      setScriptLinks(temp);

      setTimeout(() => setData(data), 0);
    } catch (error) {
      setShowAlert({ type: SHOW_ERROR_ALERT, message: t("alertMessages.error") });
    }
  }

  return (
    <>
      {data?.length ? <footer className="footer" dangerouslySetInnerHTML={{ __html: data }}></footer> : <div>Loading...</div>}
    </>
  );
}
