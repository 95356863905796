import { useTranslation } from "react-i18next";
import { BackDropModal } from "../../../components/CustomModal/backdropModal";
import { getFileName } from "../../../utils/utils";
import { Tooltip } from "@mui/material";

export const DeleteModal = ({ show, onClose, popFileName, confirmDelete }) => {
  const { t } = useTranslation();
  const popName = getFileName(popFileName, 'pop')

  return (
    <>
      {
        show && (
          <BackDropModal>
            <div
              className="modal fade show"
              id="productDeleteDemo"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="productDeleteDemo"
              data-bs-backdrop="false"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-body d-flex flex-column ps-7 pe-7">
                    <i
                      className="modal_close-button spri-icon-xmark-solid"
                      style={{ color: "rgba(53, 51, 60, 0.5)", fontSize: 28 }}
                      data-bs-dismiss="modal"
                      onClick={() => onClose()}
                    ></i>
                    <h2 className="m-0 modal_title">{t('modalOptions.confirmDelete')}</h2>
                    <div className="modal_text">
                      <Tooltip
                        title={popFileName}
                        placement="top">
                        <p>
                          {`${t('modalOptions.prDelete')} ${popName} file`}
                        </p>
                      </Tooltip>
                    </div>
                    <div className="d-flex flex-md-row flex-column" style={{ gap: 20 }}>
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() => confirmDelete()}
                      >
                        {t('modalOptions.confirm')}
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-secondary"
                        data-bs-dismiss="modal"
                        onClick={() => onClose()}
                      >
                        {t('modalOptions.cancel')}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BackDropModal>
        )
      }
    </>
  );
}