import { useState } from "react";
import { useTranslation } from "react-i18next";
import { BackDropModal } from "../../../components/CustomModal/backdropModal";

export const ProductOptionsModal = ({
  show,
  productName,
  closeOptionModal,
  showEditModal,
  deleteProductButtonPress,
}) => {
  const reasons = ['Sold or Gave Away', 'Discarded', 'Other']

  const [deleteProduct, setDeleteProduct] = useState(false);
  const [showDeleteReason, setShowDeleteReason] = useState(false);
  const [deleteReason, setDeleteReason] = useState(reasons[0]);
  const { t } = useTranslation();
  const onClose = () => {
    closeOptionModal();
    setDeleteProduct(false);
  };

  const handleEditShow = () => {
    showEditModal();
  };

  const handleRemoveProduct = () => {
    setDeleteProduct(true);
  };

  const handleConfirm = () => {
    deleteProductButtonPress(deleteReason);
    handleCancel();
  };

  const handleCancel = () => {
    setDeleteReason('')
    setShowDeleteReason(false);
    setDeleteProduct(false);
    closeOptionModal();
  };

  let deleteProductData = {
    text: t('modalOptions.prDelete') + " " + productName,
    primary: t('modalOptions.confirm'),
    sec: t('modalOptions.cancel'),
    header: t('modalOptions.productOptions')
  };

  let deleteProductReason = {
    text: t('modalOptions.productDeleteReason'),
    primary: t('modalOptions.remove'),
    sec: t('modalOptions.cancel'),
    header: t('modalOptions.productOptions')
  };

  let optionsPro = {
    text: t('modalOptions.options'),
    primary: t('modalOptions.edit'),
    sec: t('modalOptions.remove'),
    header: t('modalOptions.productOptions')
  };

  const handleModalShow = () => {
    if (deleteProduct) {
      if (showDeleteReason) {
        handleConfirm()
      } else {
        setShowDeleteReason(true)
      }
    } else {
      handleEditShow()
    }
  }

  const handleModalData = () => {
    return deleteProduct
      ? (showDeleteReason ? deleteProductReason : deleteProductData)
      : optionsPro
  }

  return (
    <>
      {show && (
        <BackDropModal>
          <div
            className="modal fade show"
            id="productOptionsDemo"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="productOptionsDemo"
            data-bs-backdrop="false"
            aria-hidden="true"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-dialog-centered" role="document">
              <ModalContent
                deleteProduct={deleteProduct}
                data={handleModalData()}
                showDeleteReason={showDeleteReason}
                showModal={() => handleModalShow()}
                removeModal={deleteProduct ? handleCancel : handleRemoveProduct}
                onClose={onClose}
                setDeleteReason={val => setDeleteReason(val)}
                reasons={reasons}
              />
            </div>
          </div>
        </BackDropModal>
      )}
    </>
  );
};

function ModalContent({
  showDeleteReason,
  deleteProduct,
  data,
  showModal,
  removeModal,
  onClose,
  setDeleteReason,
  reasons }) {
  const { text, primary: buttonPrimary, sec: buttonSec, header } = data;

  const handlePrimary = () => {
    showModal();
  };
  const handleSec = () => {
    removeModal();
  };

  return (
    <div className="modal-content">
      <div className="modal-body d-flex flex-column ps-7 pe-7">
        <i
          className="modal_close-button spri-icon-xmark-solid"
          style={{ color: "rgba(53, 51, 60, 0.5)", fontSize: 28 }}
          data-bs-dismiss="modal"
          onClick={() => onClose()}
        ></i>
        <h2 className="m-0 modal_title">{header}</h2>
        <div className="modal_text">
          <p>
            {text}
          </p>
          {showDeleteReason &&
            <>
              {reasons.map((reason, i) =>
                <div className="form-check mb-3"
                  key={reason + i}
                >
                  <input
                    onChange={() => setDeleteReason(reason)}
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id={`flexRadioDefault${i + 1}`}
                    defaultChecked={i === 0}
                  />
                  <label className="form-check-label" htmlFor={`flexRadioDefault${i + 1}`}>
                    {reason}
                  </label>
                </div>
              )}
            </>
          }
        </div>
        <div className="d-flex flex-md-row flex-column" style={{ gap: 20 }}>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => handlePrimary()}
          >
            {!deleteProduct && <i className="spri-icon-edit me-1" style={{ fontSize: "16px" }}></i>}
            {buttonPrimary}
          </button>
          <button
            type="button"
            className="btn btn-outline-secondary"
            data-bs-dismiss="modal"
            style={{ width: "205px" }}
            onClick={() => handleSec()}
          >
            {!deleteProduct && <i className="spri-icon-remove me-1" style={{ fontSize: "16px" }}></i>}
            {buttonSec}
          </button>
        </div>
      </div>
    </div>
  );
}