import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import heic2any from "heic2any";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { Tooltip } from "@mui/material";
import PdfPreview from "../../appSVG/pdf.png";
import MissingProdImag from "../../appSVG/sony_missing_image_square.png";
import { Loading } from "../../pages/login/Loading";
import { useUserPage } from '../../sprUtilityHooks/sprlocationHookcontext';
import {
  DeleteModal,
  EditProductModal,
  ProductOptionsModal,
} from "../../pages/myProducts/Modal/ProductModals";
import {
  deleteProof,
  doExternalCalls,
  getProtectionPlan,
  getS3Object,
  getS3PreSignedUrl,
} from "../../service/ProductService";
import { AppContext } from "../../store/AppProvider";
import { SHOW_ERROR_ALERT } from "../../store/reducer/AlertReducer";
import {
  checkFileType,
  fileSizeRestriction,
  getFileName,
  handleBazarVoice,
  handleContactSupport,
  handleQualtrics,
  handleRegistria,
  isFutureDate,
} from "../../utils/utils";
import { BackdropLoader } from "../CustomLoader/BackdropLoader";
import {
  addOverFlowHidden,
  removeOverFlowHidden,
} from "../CustomModal/backdropModal";
import "./Product.css";
import { ReactComponent as ProtectionSvg } from "../../appSVG/protection.svg";
import i18n from "../../i18n";

export const Product = ({ data, deleteProduct, getUpdatedProductsList }) => {
  const { t } = useTranslation();
  const { setShowAlert } = useContext(AppContext);
  const fileInputRef = useRef();
  const drop = useRef(null);
  const currentPage = useUserPage();

  const { locale } = useParams();
  const browserDetectedLocale = i18n?.resolvedLanguage;

  const [expanded, setExpanded] = useState("");

  const [protectionPlanTitle, setProtectionPlanTitle] = useState(
    t("myProducts.showProtection")
  );
  const [popData, setPopData] = useState({});
  const { popimage, pop_type } = popData;
  const [deleteModal, setDeleteModal] = useState(false);
  const [showBgLoader, setShowBgLoader] = useState(false);
  const [showPopLoader, setShowPopLoader] = useState(false);
  const [showDropzone, setShowDropzone] = useState(false);
  const [showFileInfo, setShowFileInfo] = useState(false);

  const [showOptionsModal, setShowOptionsModal] = useState(false);
  const [showProductEdit, setShowProductEdit] = useState(false);
  const [productData, setProductData] = useState(data);
  const {
    modelname,
    modeldescription,
    serialnumber,
    skuname,
    registrationdate,
    purchasedate,
    dateofdelivery,
    popfilename,
    popupdatedate,
    popfilepath,
    productimageurl,
    retailername,
    buzaarVoiceLink,
    ispopactive,
    registrationid,
    registration_warranty_end_date,
    warrantysku,
    // manufacturewarrentydesc_enca,
    // manufacturewarrentydesc_enus,
    // manufacturewarrentydesc_frca,
    popAlerts = {},
  } = productData;

  const [popButton, setPopButton] = useState({
    buttonText: t("myProducts.addProof"),
    buttonIcon: "spri-icon-Attachment",
  });

  const showProtectionPlanLink =
    Boolean(registration_warranty_end_date) &&
    isFutureDate(registration_warranty_end_date);
  const showAndAction = {
    show: { pop: "pop", protection: "protection" },
    action: { popButton: "popButton", protectionButton: "protectionButton" },
  };
  const [protectionPlans, setProtectionPlans] = useState([]);

  const [popNotification, setPopNotification] = useState(popAlerts);
  const { popNotificationType, popNotificationMessage } = popNotification;

  useEffect(() => {
    drop?.current?.addEventListener("dragover", handleDragOver);
    drop?.current?.addEventListener("drop", handleDrop);

    return () => {
      drop?.current?.removeEventListener("dragover", handleDragOver);
      drop?.current?.removeEventListener("drop", handleDrop);
    };
  }, []);

  useEffect(() => {
    buttonIconAndText();
  }, [expanded, ispopactive]);

  const buttonIconAndText = () => {
    let dplObj = {
      buttonText: t("myProducts.addProof"),
      buttonIcon: "spri-icon-View",
    };
    if (!ispopactive) {
      dplObj["buttonText"] = t("myProducts.addProof");
      dplObj["buttonIcon"] = "spri-icon-Attachment";
    } else if (Boolean(expanded) && !ispopactive) {
      dplObj = {};
    } else if (Boolean(expanded === showAndAction.show.pop) && ispopactive) {
      dplObj["buttonText"] = t("myProducts.hideProof");
    } else if (!Boolean(expanded === showAndAction.show.pop) && ispopactive) {
      dplObj["buttonText"] = t("myProducts.viewProof");
    }
    setPopButton(dplObj);
  };

  const viewFile = (type) => {
    switch (type) {
      case "pdf":
        openPDF();
        break;
      default:
        var image = new Image();
        image.src = popimage;
        var w = window.open("");
        w.document.write(image.outerHTML);
        break;
    }
  };

  const openPDF = () => {
    window.open(popimage, "_blank");
  };

  const getPopPreviewImage = (fileType) => {
    return fileType === "pdf" ? PdfPreview : popimage;
  };

  const onFileSelect = (event) => {
    event?.preventDefault();
    const file = event.target?.files[0];
    if (!Boolean(file)) return;
    if (fileSizeRestriction(file)) {
      fileInputRef.current.value = "";
      setPopNotification({
        popNotificationType: "ERROR",
        popNotificationMessage: t("alertMessages.fileSizeExceeded"),
      });
      return;
    }
    const isHeicFile =
      file?.name?.split(".")[file?.name?.split(".").length - 1] === "heic";
    if (isHeicFile) {
      uploadHeicFile(file);
    } else {
      if (!checkFileType(file)) {
        fileInputRef.current.value = "";
        setPopNotification({
          popNotificationType: "ERROR",
          popNotificationMessage: t("alertMessages.fileError"),
        });
        return;
      }
      onFileChange(file, file?.name);
    }
  };

  const uploadHeicFile = async (heicFileData) => {
    try {
      setShowPopLoader(true);
      setShowDropzone(false);
      setShowFileInfo(false);
      const heicFile = await convertHeicToJpeg(heicFileData);
      onFileChange(heicFile, heicFile?.name);
    } catch (error) {
      fileInputRef.current.value = "";
      setShowDropzone(true);
      setPopNotification({
        popNotificationType: "ERROR",
        popNotificationMessage: t("alertMessages.heicConversionError"),
      });
    } finally {
      setShowPopLoader(false);
    }
  };

  const convertHeicToJpeg = async (file) => {
    const jpegFile = await heic2any({
      blob: file,
      toType: "image/jpeg",
      quality: 0.94,
    });
    jpegFile.lastModifiedDate = new Date();
    jpegFile.name = `${file?.name?.split(".")?.[0]}.jpg`;
    return jpegFile;
  };

  const handleOptionsModal = () => {
    if (Boolean(popNotificationType)) {
      setPopNotification({});
    }
    addOverFlowHidden();
    setShowOptionsModal(true);
  };

  const onClose = () => {
    removeOverFlowHidden();
    setShowOptionsModal(false);
  };

  const handleEditShow = () => {
    setShowOptionsModal(false);
    setShowProductEdit(true);
  };

  const onCloseEdit = () => {
    removeOverFlowHidden();
    setShowProductEdit(false);
  };

  const onSubmitEdit = (submittedData) => {
    getUpdatedProductsList({
      registrationid: submittedData?.registrationid,
      popNotificationType: "SUCCESS",
      popNotificationMessage: t("alertMessages.update"),
    });
    removeOverFlowHidden();
    setShowProductEdit(false);
    if (Boolean(expanded)) {
      handleButtonToggle("");
    }
  };

  const handleDragOver = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
  };

  const handleDrop = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    const { files } = e.dataTransfer;
    if (files?.length > 1) {
      setPopNotification({
        popNotificationType: "ERROR",
        popNotificationMessage: t("alertMessages.multipleFileError"),
      });
      return;
    }
    if (fileSizeRestriction(files?.[0])) {
      fileInputRef.current.value = "";
      setPopNotification({
        popNotificationType: "ERROR",
        popNotificationMessage: t("alertMessages.fileSizeExceeded"),
      });
      return;
    }
    if (files && files?.length) {
      const file = files?.[0];
      const isHeicFile =
        file?.name?.split(".")[file?.name?.split(".").length - 1] === "heic";
      if (isHeicFile) {
        uploadHeicFile(file);
      } else {
        if (!checkFileType(file)) {
          setPopNotification({
            popNotificationType: "ERROR",
            popNotificationMessage: t("alertMessages.fileError"),
          });
          return;
        }
        onFileChange(file, file?.name);
      }
    }
  };

  const onFileChange = async (file, filename) => {
    try {
      setShowBgLoader(true);
      const popRegistrationId = registrationid;
      const popPath =
        purchasedate?.split("-")[0] + "-" + purchasedate?.split("-")[1];
      const payload = {
        registration_id: popRegistrationId,
        file_name: filename,
        file_path: `${popPath}/${popRegistrationId}_${file?.name}`,
        file_type: file?.type,
        file_size: file?.size,
      };
      const metadata = await getS3PreSignedUrl(payload);

      const S3Url = metadata?.data?.split("&x-amz-security-token")[0];
      const data = await axios({
        method: "PUT",
        url: S3Url,
        data: file,
        headers: { "Content-Type": file.type },
      });

      doExternalCalls(metadata.token);
      if (data) {
        const newState = {
          ...productData,
          popimage: "",
          ispopactive: true,
          popfilepath: `${popPath}/${popRegistrationId}_${file.name}`,
          popfilename: filename,
          popupdatedate: new Date(),
        };
        setProductData(newState);
        handleButtonToggle("");
        setPopNotification({
          popNotificationType: "SUCCESS",
          popNotificationMessage: t("alertMessages.popSuccess"),
        });
      } else {
        setPopNotification({
          popNotificationType: "ERROR",
          popNotificationMessage: t("alertMessages.catch"),
        });
      }
    } catch (error) {
      setPopNotification({
        popNotificationType: "ERROR",
        popNotificationMessage: error?.message,
      });
    } finally {
      setShowBgLoader(false);
    }
  };

  const handleButtonToggle = (show, button = "") => {
    let changeText = "";
    switch (show) {
      case "":
        changeText = "";
        break;
      case showAndAction.show.protection:
      case showAndAction.show.pop:
        if (expanded === "") {
          if (button === showAndAction.action.popButton) {
            changeText = showAndAction.show.pop;
          } else if (button === showAndAction.action.protectionButton) {
            changeText = showAndAction.show.protection;
          } else {
            changeText = "";
          }
        } else if (expanded === showAndAction.show.protection) {
          if (button === showAndAction.action.protectionButton) {
            changeText = "";
          } else if (button === showAndAction.action.popButton) {
            changeText = showAndAction.show.pop;
          }
        } else if (expanded === showAndAction.show.pop) {
          if (button === showAndAction.action.popButton) {
            changeText = "";
          } else if (button === showAndAction.action.protectionButton) {
            changeText = showAndAction.show.protection;
          }
        }
        break;
      default:
        break;
    }
    setExpanded(changeText);
  };

  const onViewPop = async (popfilename, buttonText) => {
    if (Boolean(popNotificationType)) {
      setPopNotification({});
    }
    switch (buttonText) {
      case t("myProducts.addProof"):
        setShowDropzone(!showDropzone);
        handleButtonToggle(
          showAndAction.show.pop,
          showAndAction.action.popButton
        );
        setProtectionPlanTitle(t("myProducts.showProtection"));
        break;
      case t("myProducts.viewProof"):
        try {
          setShowDropzone(false);
          setShowFileInfo(false);
          handleButtonToggle(
            showAndAction.show.pop,
            showAndAction.action.popButton
          );
          if (protectionPlanTitle === t("myProducts.hideProtection")) {
            setProtectionPlanTitle(t("myProducts.showProtection"));
          }
          setShowPopLoader(true);
          const popRegistrationId = registrationid;
          const payload = {
            registration_id: popRegistrationId,
            file_name: popfilename,
            file_path: popfilepath,
            type: "getPop",
          };
          const data = await getS3Object(payload);
          const popimage = data;
          const pop_type = data?.split("?")[0]?.split(".")[
            data?.split("?")[0]?.split(".")?.length - 1
          ];

          if (data) {
            setShowFileInfo(true);
            setPopData({ popimage, pop_type });
          }
        } catch (error) {
          setShowAlert({ type: SHOW_ERROR_ALERT, message: error.message });
        } finally {
          setShowPopLoader(false);
          break;
        }
      case t("myProducts.hideProof"):
        handleButtonToggle("");
        setShowFileInfo(false);
        if (showPopLoader) {
          setShowPopLoader(false);
        }
      default:
        break;
    }
  };

  const openDeleteModal = () => {
    addOverFlowHidden();
    setDeleteModal(true);
  };

  const closeDeleteModal = () => {
    removeOverFlowHidden();
    setDeleteModal(false);
  };

  const deletePop = async () => {
    try {
      setShowBgLoader(true);
      const popRegistrationId = registrationid;
      const data = await deleteProof(popRegistrationId);
      if (data) {
        getUpdatedProductsList({
          registrationid,
          popNotificationType: "SUCCESS",
          popNotificationMessage: t("alertMessages.popDelete"),
        });
        doExternalCalls(data?.token);
      } else {
        setPopNotification({
          popNotificationType: "ERROR",
          popNotificationMessage: t("alertMessages.catch"),
        });
      }
    } catch (error) {
      setPopNotification({
        popNotificationType: "ERROR",
        popNotificationMessage: error?.message,
      });
    } finally {
      closeDeleteModal();
      setShowBgLoader(false);
    }
  };

  const handleCloseIconPress = () => {
    handleButtonToggle("");
    setShowDropzone(false);
    if (protectionPlanTitle === t("myProducts.hideProtection")) {
      setProtectionPlanTitle(t("myProducts.showProtection"));
    }
    if (Boolean(popNotificationType)) {
      setPopNotification({});
    }
  };

  const onShowProtectionPlan = async () => {
    if (Boolean(popNotificationType)) {
      setPopNotification({});
    }
    setShowDropzone(false);
    setProtectionPlans([]);
    if (!Boolean(expanded) || expanded === showAndAction.show.pop) {
      if (protectionPlanTitle === t("myProducts.hideProtection")) {
        setProtectionPlanTitle(t("myProducts.showProtection"));
        return;
      }
      try {
        setShowPopLoader(true);
        handleButtonToggle(
          showAndAction.show.protection,
          showAndAction.action.protectionButton
        );
        const data = await getProtectionPlan(registrationid);
        if (Boolean(data?.length)) {
          setProtectionPlanTitle(t("myProducts.hideProtection"));
          setProtectionPlans(data);
        } else {
          setShowAlert({
            type: SHOW_ERROR_ALERT,
            message: t("alertMessages.noProtection"),
          });
        }
      } catch (error) {
        setProtectionPlans([]);
        handleButtonToggle("");
        setShowAlert({
          type: SHOW_ERROR_ALERT,
          message: t("alertMessages.catch"),
        });
      } finally {
        setShowPopLoader(false);
      }
    } else {
      setProtectionPlanTitle(t("myProducts.showProtection"));
      handleButtonToggle(
        showAndAction.show.protection,
        showAndAction.action.protectionButton
      );
    }
  };

  const addProtectionPlan = () => {

    var event = new CustomEvent("componentInteraction", {
      detail: {
        page: currentPage,
        type: "click add-plan",
        event: "interactive click",
        label: "Add Protection Plan",
      },
    });
    console.log(event)
    document.dispatchEvent(event);
    handleRegistria(
      "",
      registrationid,
      {
        serial_number: serialnumber,
        sku: skuname,
        purchase_date: purchasedate,
        delivery_date: dateofdelivery,
      },
      browserDetectedLocale,
      true,
      false
    );
  };

  // const manufacturer_warranty = {
  //   manufacturewarrentydesc_enca: manufacturewarrentydesc_enca,
  //   manufacturewarrentydesc_enus: manufacturewarrentydesc_enus,
  //   manufacturewarrentydesc_frca: manufacturewarrentydesc_frca
  // }
  // const browserLocale = locale?.replace(/[_-]/g, "")?.toLowerCase() || 'enus';
  // const manufacturer_warranty_desc = manufacturer_warranty[`manufacturewarrentydesc_${browserLocale}`] || t('myProducts.defaultManufacturerWarranty');

  const writeAReview = () => {
    var event = new CustomEvent("componentInteraction", {
      detail: {
        page: currentPage,
        type: "click write-review",
        event:"interactive click",
        label: "Write a Review",
      },
    });
    console.log(event)
    document.dispatchEvent(event);
    handleBazarVoice(buzaarVoiceLink, locale);
  };
  const takeAServey = () => {
    var event = new CustomEvent("componentInteraction", {
      detail: {
        page: currentPage,
        type: "click take-survey",
        event:"interactive click",
        label: "Take a Quick Survey",
      },
    });
    console.log(event)
    document.dispatchEvent(event);
    handleQualtrics(modelname);
  };
 
  const contectSupport = () => {
    var event = new CustomEvent("componentInteraction", {
      detail: {
        page: currentPage,
        type: "click contact-support",
        event: "interactive click",
        label: "Contact support",
      },
    });
    console.log(event)
    document.dispatchEvent(event);
    handleContactSupport(locale)
  };

  return (
    <>
      <div
        id="product-card-template"
        className="d-flex flex-column product-card"
      >
        <div className="col-lg-12 d-flex flex-column flex-md-row justify-content-between">
          <div className="col-lg-8 d-flex flex-column">
            <Tooltip title={modeldescription} placement="bottom-start">
              <h2 className="product-card__title">
                {getFileName(modeldescription || modelname, "model_name", 40)}
              </h2>
            </Tooltip>
            <div className="product-card__info d-flex flex-row">
              <div className="product-card__info-title">
                <div>{t("myProducts.Serial Number")}&nbsp;</div>
                <div>{t("myProducts.Registered On")}&nbsp;</div>
                <div>{t("myProducts.Purchased On")}&nbsp;</div>
                <div>{t("myProducts.Delivered On")}&nbsp;</div>
                <div> {t("myProducts.Purchased At")}&nbsp;</div>
                {/* <div className="manufacturer"> {t("myProducts.Manufacturer Warranty")}&nbsp;</div> */}
              </div>
              <div className="product-card__info-text">
                <div className="product-card__serial-number">
                  :&nbsp;{serialnumber}
                </div>
                <div className="product-card__registered-on">
                  :&nbsp;
                  {!!registrationdate
                    ? new Date(registrationdate)?.toLocaleDateString()
                    : ""}
                </div>
                <div className="product-card__purchased-on">
                  :&nbsp;
                  {!!purchasedate
                    ? new Date(purchasedate)?.toLocaleDateString()
                    : ""}
                </div>
                <div className="product-card__delivered-on">
                  :&nbsp;
                  {!!dateofdelivery
                    ? new Date(dateofdelivery)?.toLocaleDateString()
                    : ""}
                </div>
                <div className="product-card__purchased-at">
                  :&nbsp;{retailername}
                </div>
                {/* <div className="product-card__manufacturer">:&nbsp;{manufacturer_warranty_desc}</div> */}
              </div>
            </div>
          </div>
          <div className="col-lg-4 d-flex flex-column align-items-md-end">
            <img
              className="product-card__image"
              src={productimageurl || MissingProdImag}
              alt={modelname}
            />
          </div>
        </div>
        {/* Add Proof of Purchase */}
        <div className="col-lg-12 d-flex flex-row flex-wrap text-button-wrapper">
          <button
            className="toggleButton d-flex justify-content-center align-items-center text-button"
            type="button"
            aria-expanded="false"
            aria-controls="proofOfPurchase0"
            style={{ marginRight: 20 }}
            onClick={() => onViewPop(popfilename, popButton?.["buttonText"])}
          >
            <i
              aria-hidden="true"
              className={`icon ${popButton?.["buttonIcon"]}`}
              style={{ fontSize: 18, marginRight: 3 }}
            ></i>
            <span className="buttonText">{popButton?.["buttonText"]}</span>
          </button>
          {showProtectionPlanLink && (
            <button
              className="toggleButton d-flex justify-content-center align-items-center pr-text-button"
              type="button"
              aria-expanded="false"
              aria-controls="protectionPlan"
              onClick={() => onShowProtectionPlan()}
            >
              <ProtectionSvg />
              <span className="buttonText">{protectionPlanTitle}</span>
            </button>
          )}
        </div>
        <div
          id="proofOfPurchase0"
          className={`proofOfPurchase collapse col-lg-12 mt-3 mb-2 ${
            Boolean(expanded) ? "show" : "d-none"
          }`}
          ref={drop}
        >
          {/* PROOF OF PURCHASE */}
          <div
            className={`d-flex justify-content-between align-items-center ${
              expanded === showAndAction.show.pop ? "show" : "d-none"
            }`}
          >
            {/* DROPZONE */}
            <div
              className={`dropZone d-flex flex-column justify-content-center align-items-center drop-zone ${
                showDropzone ? "d-block" : "d-none"
              }`}
            >
              <input
                ref={fileInputRef}
                type="file"
                className="fileInput"
                id="fileInput"
                style={{ display: "none" }}
                onChange={(e) => onFileSelect(e)}
                accept={"image/*,.heic,.heif,application/pdf"}
              />
              <div className="drop-zone__title">
                {t("myProducts.dragFiles")}
              </div>
              <button
                className="chooseFileBtn accent-button btn btn-primary"
                onClick={() => fileInputRef.current.click()}
              >
                {t("myProducts.chooseFiles")}
              </button>
              <button
                aria-controls="proofOfPurchase0"
                aria-expanded="false"
                className="close-button d-flex justify-content-center align-items-center text-button"
                type="button"
                onClick={() => handleCloseIconPress()}
              >
                <i
                  aria-hidden="true"
                  className="icon spri-icon-xmark-solid"
                  style={{ fontSize: 20 }}
                ></i>
              </button>
            </div>

            {/* FILE INFO */}
            {showFileInfo && (
              <div
                className={`fileInfo d-flex flex-column flex-sm-row product-card__file`}
              >
                <div className="mb-3 mb-xs-0 product-card__file-preview">
                  <img
                    className="imagePreview"
                    src={getPopPreviewImage(pop_type)}
                    alt="preview"
                  />
                  <canvas
                    className="pdfPreview"
                    style={{ display: "none" }}
                  ></canvas>
                </div>

                <div className="d-flex flex-column justify-content-center product-card__file-info">
                  <div className="product-card__info d-flex flex-row">
                    <div className="product-card__info-title">
                      <div style={{ width: "35vw" }}>
                        {t("myProducts.File Name")}:
                        <Tooltip title={popfilename} placement="top-start">
                          <>
                            {getFileName(popfilename, showAndAction.show.pop)}
                          </>
                        </Tooltip>
                      </div>
                      <div>
                        {t("myProducts.File Uploaded")}:{" "}
                        {new Date(popupdatedate)?.toLocaleDateString() || ""}
                      </div>
                    </div>
                    <div className="product-card__info-text">
                      <div className="fileName file-name"></div>
                      <div className="fileUploaded file-uploaded"></div>
                    </div>
                  </div>

                  <div
                    className="btn-group"
                    role="group"
                    aria-label="File Actions"
                  >
                    <button
                      className="viewButton text-button"
                      onClick={() => viewFile(pop_type)}
                    >
                      {t("common.View")}
                    </button>
                    <button
                      className="updateButton text-button"
                      onClick={() => fileInputRef.current.click()}
                    >
                      {t("common.Update")}
                    </button>
                    <button
                      className="deleteButton text-button"
                      onClick={() => openDeleteModal()}
                    >
                      {t("common.Delete")}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {/* LOADER */}
            {showPopLoader && (
              <div className="view-pop-loader d-flex flex-column justify-content-center align-items-center">
                <Loading show={true} />
              </div>
            )}
          </div>
          {/* PROTECTION-ZONE */}
          <div
            className={`d-flex justify-content-between align-items-center ${
              expanded === showAndAction.show.protection ? "show" : "d-none"
            }`}
          >
            {!showPopLoader && Boolean(protectionPlans?.length) && (
              <div
                className="dropZone protection-zone d-block" //d-flex flex-column justify-content-center align-items-center
              >
                <>
                  <div className="protection-title">
                    {t("myProducts.protectionPlan")}
                  </div>
                  <div className="pr-divider"></div>
                  <ol>
                    {protectionPlans?.map(
                      ({
                        protection_plan_id,
                        warranty_type,
                        purchase_plan_name,
                        purchase_end_date,
                      }) => (
                        <li className="pr-plans" key={protection_plan_id}>
                          <h3>{purchase_plan_name}</h3>
                          <div>
                            <strong>{t("myProducts.Entitlement Type")}:</strong>
                            &nbsp;{(warranty_type || "")?.replace("_", " ")}
                          </div>
                          <div>
                            <strong>{t("myProducts.End Date")}:</strong>
                            &nbsp;
                            {!!purchase_end_date
                              ? new Date(
                                  purchase_end_date
                                )?.toLocaleDateString()
                              : ""}
                          </div>
                        </li>
                      )
                    )}
                  </ol>
                </>

                <button
                  aria-controls="protectionPlan"
                  aria-expanded="false"
                  className="close-button d-flex justify-content-center align-items-center text-button"
                  type="button"
                  onClick={() => handleCloseIconPress()}
                >
                  <i
                    aria-hidden="true"
                    className="icon spri-icon-xmark-solid"
                    style={{ fontSize: 20 }}
                  ></i>
                </button>
              </div>
            )}
            {/* LOADER */}
            {showPopLoader && (
              <div className="view-pop-loader d-flex flex-column justify-content-center align-items-center">
                <Loading show={true} />
              </div>
            )}
          </div>
        </div>
        <div
          className={`${
            popNotificationType === "SUCCESS" ? "success-notifier" : ""
          }${popNotificationType === "ERROR" ? "error-notifier" : ""}`}
        >
          {popNotificationMessage}
        </div>
        <div className="col-lg-12 d-flex flex-column">
          <div className="divider"></div>
          <div className="d-flex flex-row col-lg-12">
            <div className="d-flex flex-row flex-wrap product-card__footer">
              <button
                className="outline-button btn btn-outline-secondary"
                onClick={() => writeAReview()}
              >
                {t("myProducts.Write a Review")}
              </button>
              <button
                className="outline-button btn btn-outline-secondary"
                onClick={() => takeAServey()}
              >
                {t("myProducts.Take a Quick Survey")}
              </button>
              {Boolean(warrantysku) && (
                <button
                  className="outline-button btn btn-outline-secondary"
                  onClick={() => addProtectionPlan()}
                >
                  {t("myProducts.Add Protection Plan")}
                </button>
              )}
              <button
                className="outline-button btn btn-outline-secondary"
                onClick={()=>contectSupport()}
              >
                {t("myProducts.Contact Support")}
              </button>
            </div>
            <button
              className="menu-button"
              type="button"
              onClick={() => handleOptionsModal()}
              aria-label="Menu button"
              title="Menu button"
            >
              <i
                aria-hidden="true"
                className="spri-icon-Menu"
                style={{ fontSize: 5 }}
              ></i>
            </button>
          </div>
        </div>
      </div>
      <ProductOptionsModal
        show={showOptionsModal}
        productName={modelname}
        closeOptionModal={onClose}
        showEditModal={handleEditShow}
        deleteProductButtonPress={(deleteReason) =>
          deleteProduct(registrationid, deleteReason)
        }
      />
      <DeleteModal
        show={deleteModal}
        onClose={closeDeleteModal}
        popFileName={popfilename}
        confirmDelete={() => deletePop()}
      />
      {showProductEdit && (
        <EditProductModal
          data={productData}
          show={showProductEdit}
          closeEditModal={onCloseEdit}
          onSubmitEdit={onSubmitEdit}
          handlePopNotification={(alertType, alertMessage) =>
            setPopNotification({
              popNotificationType: alertType,
              popNotificationMessage: alertMessage,
            })
          }
        />
      )}
      <BackdropLoader show={showBgLoader} size={"medium"} type="fullscreen" />
    </>
  );
};
