


export const BackDropModal = (props) => {
    return (
        <div className="backdrop-modal">
            {props.children}
        </div>
    )
}


export const removeOverFlowHidden = () => (
    document.body.classList.remove('overflow-hidden')
)

export const addOverFlowHidden = () => (
    document.body.classList.add('overflow-hidden')
)