import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { languageOptions } from "./utils/dataConstants";


const convertParamsToLang = (params) => {
	return params ? (params.slice(0, 3) + params.slice(3).toUpperCase()).replace('-', "_") : null;
};

const currentDomain = () => {
	const url = window.location.host;
	return url.includes('.ca') ? 'ca' : url.includes('.com') ? 'com' : undefined;
}

const domainDetector = {

	name: 'domain',

	lookup(options) {
		const params = convertParamsToLang(window.location.pathname.split('/')[1])
		if (Object.keys(languageOptions).includes(params)) {
			return convertParamsToLang(params)
		}
		switch (currentDomain()) {
			case "ca":
				return 'en_CA'
			case "com":
				return 'en_US'
		}
	},
};
const languagedetector = new LanguageDetector();
languagedetector.addDetector(domainDetector);

i18n.use(initReactI18next).use(languagedetector).init({
	resources: {
		en_US: {
			translation: require("./locales/en_US/translation.json"),
		},
		es_US: {
			translation: require("./locales/es_US/translation.json"),
		},
		en_CA: {
			translation: require("./locales/en_CA/translation.json"),
		},
		fr_CA: {
			translation: require("./locales/fr_CA/translation.json"),
		},
	},
	detection: {
		order: ['domain'],
		convertDetectedLanguage: convertParamsToLang
	},
	fallbackLng: "en_US",
	debug: false, // to hide i18 warnings
	interpolation: { escapeValue: false },
});

export default i18n;
