import { setSessionStorage } from "../../service/SessionStorageService";
import { loggedInUser } from "../../utils/Constants";

export const loggedInUserInitialState = { first_name: sessionStorage.getItem('loggedInUser') || null , id: ''};


export const UPDATE_LOGGED_IN_USER = "UPDATE_LOGGED_IN_USER";

export const loggedInUserReducer = (state, { type, payload }) => {
	switch (type) {
		case UPDATE_LOGGED_IN_USER:
			setSessionStorage(loggedInUser, payload?.first_name);
			return { ...state, first_name: payload?.first_name  , id : payload.id ? payload.id : "not auth yet"};
		default:
			return state;
	}
};