import { createContext, useReducer } from "react";
import { alertInitialState, alertReducer } from "./reducer/AlertReducer";
import { loaderInitialState, loaderReducer } from "./reducer/BLoaderReducer";
import { localeInitialState, localeReducer } from "./reducer/LocaleReducer";
import { loggedInUserInitialState, loggedInUserReducer } from "./reducer/LoggedInUserReducer";
import { stateCodeInitialState, stateCodeReducer } from "./reducer/StateReducer";

export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
	const [showBLoader, setShowBLoader] = useReducer(loaderReducer, loaderInitialState);
	const [localeLng, setLocaleLng] = useReducer(localeReducer, localeInitialState);
	const [showAlert, setShowAlert] = useReducer(alertReducer, alertInitialState);
	const [loggedInUser, setLoggedInUser] = useReducer(loggedInUserReducer, loggedInUserInitialState);
	const [stateCode, setStateCode] = useReducer(stateCodeReducer, stateCodeInitialState);

	const store = {
		showBLoader, setShowBLoader,
		localeLng, setLocaleLng,
		showAlert, setShowAlert,
		loggedInUser, setLoggedInUser,
		stateCode, setStateCode
	};

	return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
};
