import { Alert, Snackbar } from "@mui/material";
import React, { useContext } from "react";
import { AppContext } from "../../store/AppProvider";
import { HIDE_ALERT } from "../../store/reducer/AlertReducer";

export const AlertNotifier = () => {
	const {
		showAlert: { showAlert, alertMessage, error },
		setShowAlert,
	} = useContext(AppContext);
	return (
		<div>
			{showAlert && (
				<Snackbar open={true} autoHideDuration={3000} onClose={() => setShowAlert({ type: HIDE_ALERT })} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
					<Alert onClose={() => setShowAlert({ type: HIDE_ALERT })} severity={error ? "error" : "success"} sx={{ width: "100%" }}>
						{alertMessage || ""}

					</Alert>
				</Snackbar>
			)}
		</div>
	);
};