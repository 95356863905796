const CarouselView = ({HeroLogin1,HeroLogin2,HeroLogin3,HeroLogin4}) => {
    return (
        <div className="hero-container d-lg-flex d-none">
          <div className=" col-lg-5 col-12"></div>
          <div
            id="heroCarousel"
            data-bs-interval="12000"
            data-bs-ride="carousel"
            className="carousel slide carousel-fade col-lg-7"
          >
            <ol className="carousel-indicators" id="hero-carousel-indicators"></ol>

            <div className="carousel-inner" role="listbox">
              {/* <!-- Slide 1 --> */}
              <div className="carousel-item active">
                <div className="carousel-container">
                  <div
                    className="carousel-content"
                    style={{ backgroundImage: `url(${HeroLogin1})` }}
                  ></div>
                </div>
              </div>
              {/* <!-- Slide 2 --> */}
              <div className="carousel-item">
                <div className="carousel-container">
                  <div
                    className="carousel-content"
                    style={{ backgroundImage: `url(${HeroLogin2})` }}
                  ></div>
                </div>
              </div>
              {/* <!-- Slide 3 --> */}
              <div className="carousel-item">
                <div className="carousel-container">
                  <div
                    className="carousel-content"
                    style={{ backgroundImage: `url(${HeroLogin3})` }}
                  ></div>
                </div>
              </div>
              {/* <!-- Slide 4 --> */}
              <div className="carousel-item">
                <div className="carousel-container">
                  <div
                    className="carousel-content"
                    style={{ backgroundImage: `url(${HeroLogin4})` }}
                  ></div>
                </div>
              </div>
              <div className="bg"></div>
            </div>
          </div>
        </div>

    )
}

export default CarouselView;