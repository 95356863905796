const CarouselMobileView = ({HeroLogin1,HeroLogin2,HeroLogin3,HeroLogin4}) => {
    return (
        <div id="carouselExampleIndicators" className="carousel d-lg-none d-flex">
          <ul className="carousel-indicators">
            <li
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
            ></li>
            <li
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
            ></li>
            <li
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
            ></li>
            <li
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="3"
            ></li>
          </ul>
          <ol className="carousel-indicators"></ol>
          <div className="carousel-inner">
            {/* <!-- Slide 1 --> */}
            <div className="carousel-item active">
              <div className="carousel-container">
                <div
                  className="carousel-content"
                  style={{ backgroundImage: `url(${HeroLogin1})` }}
                ></div>
              </div>
            </div>
            {/* <!-- Slide 2 --> */}
            <div className="carousel-item">
              <div className="carousel-container">
                <div
                  className="carousel-content"
                  style={{ backgroundImage: `url(${HeroLogin2})` }}
                ></div>
              </div>
            </div>
            {/* <!-- Slide 3 --> */}
            <div className="carousel-item">
              <div className="carousel-container">
                <div
                  className="carousel-content"
                  style={{
                    backgroundImage: `url(${HeroLogin3})`,
                    backgroundPosition: "center",
                  }}
                ></div>
              </div>
            </div>
            {/* <!-- Slide 4 --> */}
            <div className="carousel-item">
              <div className="carousel-container">
                <div
                  className="carousel-content"
                  style={{
                    backgroundImage: `url(${HeroLogin4})`,
                    backgroundPosition: "center",
                  }}
                ></div>
              </div>
            </div>
            <div className="bg"></div>
          </div>
        </div>
    )
}

export default CarouselMobileView;