import { useTranslation } from "react-i18next";

export default function LoginHeader({ signIn, signUp }) {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="col-lg-5  col-12 hero-container__info">
        <h2>{t("login.manageProduct")}</h2>
        <SignInBlock signIn={signIn} signUp={signUp} t={t} />
        <SignInBlockMobileView signIn={signIn} signUp={signUp} t={t} />
      </div>
    </div>
  );
}

function SignInBlock({ signIn, signUp, t }) {
  return (
    <div
      className="d-none d-md-flex justify-content-between hero-container__block__btn"
      style={{ marginRight: "15px" }}
    >
      <a href="" className="login" onClick={(e) => signIn(e)}>
        {t(`login.Login`)}
      </a>
      <a href="" className="account" onClick={(e) => signUp(e)}>
        {t("login.Create Account")}
      </a>
    </div>
  );
}

function SignInBlockMobileView({ signIn, signUp, t }) {
  return (
    <div className="d-flex d-md-none flex-column justify-content-center align-items-center">
      <a href="" className="account" onClick={(e) => signUp(e)}>
        {t("login.Create Account")}
      </a>
      <a href="" className="login mt-3" onClick={(e) => signIn(e)}>
        {t("login.Login")}
      </a>
    </div>
  );
}
