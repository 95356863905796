import { useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { xhrCall } from "../../utils/Constants";
import { handleLogout } from "../../utils/utils";
import { useParams } from "react-router";


export const IdleTimeTracker = (props) => {

    // const locale  = i18n.resolvedLanguage;
    const { locale } = useParams()
    // const handleOnIdle = async function () {

    //     const response = await deleteToken();
    //     const queryParams = `client_id=${app_client_id}&scope=openid+users&redirect_uri=${signout_uri}&state=${state}`;
    //     let logoutUrl = signout_url + queryParams;
    //     window.location.href = logoutUrl;

    // }


    const { reset: resetIdleTimer } = useIdleTimer({
        timeout: props.idleTimeOut,
        onIdle: () => handleLogout(locale, true),
        debounce: 500,
        events: []
    });

    const handleResetIdleTimer = function (e) {
        resetIdleTimer();
    }


    useEffect(function () {
        window.addEventListener(xhrCall, handleResetIdleTimer);

        return function () { window.removeEventListener(xhrCall, handleResetIdleTimer) }
    });

    return "";

}