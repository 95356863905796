import { useTranslation } from "react-i18next";
import "./BackdropLoader.css";

export const BackdropLoader = ({ show, type, size }) => {
  const { t } = useTranslation()
  const customStyle = {};
  if (size === "medium") {
    customStyle["height"] = 100;
    customStyle["width"] = 100;
  }
  let customLoader = type !== "fullscreen" ? "loader" : "fLoader";
  return (
    <>
      {show && (
        <div
          className={`d-flex justify-content-center align-items-center ${customLoader}`}
          style={{ zIndex: 9999 }}
        >
          <div
            className="spinner-border text-primary"
            style={customStyle}
            role="status"
          >
            <span className="visually-hidden">{t('common.Loading')}...</span>
          </div>
        </div>
      )}
    </>
  );
};

export const ButtonLoader = () => {
  const { t } = useTranslation()
  return (
    <div className="d-flex justify-content-end">
      <button
        id="loading"
        className="btn btn-primary"
        style={{ marginTop: 0 }}
        type="button"
        disabled
      >
        <span
          id="61"
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
          style={{ marginRight: 10 }}
        ></span>
        {t('common.Loading')}
      </button>
    </div>
  );
};

export const ModalButtonLoader = () => {
  const { t } = useTranslation()
  return (
    <div className="d-flex px-0 w-100">
      <button
        id="loading"
        className="btn btn-primary mb-4 mb-md-0 ms-auto me-md-0 me-auto"
        style={{ marginTop: 0 }}
        type="button"
        disabled
      >
        <span
          id="61"
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        {t('common.Loading')}
      </button>
    </div>
  );
};
