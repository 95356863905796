export const loaderInitialState = {
  showLoader: false,
  type: "fullscreen",
  size: "medium",
};

export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

export const loaderReducer = (state, { action, type, size }) => {
  switch (action) {
    case HIDE_LOADER:
      return { ...state, showLoader: false };
    case SHOW_LOADER:
      return {
        ...state,
        showLoader: true,
        type: type || "fullscreen",
        size: size || "medium",
      };
    default:
      return state;
  }
};
