import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import heic2any from "heic2any";
import { useForm, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import MaskedInput from "react-text-mask";
import {
  BackdropLoader,
  ModalButtonLoader,
} from "../../../components/CustomLoader/BackdropLoader";
import { BackDropModal } from "../../../components/CustomModal/backdropModal";
import {
  doExternalCalls,
  getMandatoryFields,
  getRetailerList,
  registerProductOfUser,
} from "../../../service/ProductService";
import { AppContext } from "../../../store/AppProvider";
import {
  SHOW_ERROR_ALERT
} from "../../../store/reducer/AlertReducer";
import { currencyMask, defaultDynFormParams, languageOptions, purchaseType } from "../../../utils/dataConstants";
import { checkFileType, convertDate, fileSizeRestriction, isEmptyObj, isJSON, withoutTime, refFutureDate } from "../../../utils/utils";
import i18n from "../../../i18n";
import './ModalStyle.css'

export const EditProductModal = ({
  data: {
    productid,
    campaignid: product_campaign_id,
    serialnumber,
    skuname,
    popfilename,
    purchasedate,
    dateofdelivery,
    pricepaid,
    purchasetype,
    retailerid,
    registrationid,
    registriaid,
    retailername,
    retailassociate,
    ispopactive
  },
  show,
  handlePopNotification,
  closeEditModal,
  onSubmitEdit
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    resetField,
    setError,
    formState: { touchedFields, errors },
    control
  } = useForm({
    defaultValues: {
      purchaseDate: convertDate(purchasedate),
      retailerName: retailername
    }
  });
  const { t } = useTranslation();
  const popInputRef = useRef();

  const {
    localeLng: { country },
    stateCode: { state_code },
    setShowAlert
  } = useContext(AppContext);

  const [retailerOptions, setRetailerOptions] = useState([]);
  const [selectedPurchaseType, setSelectedPurchaseType] = useState(purchasetype);
  const [showBLoader, setShowBLoader] = useState(false);
  const [uploadProof, setUploadProof] = useState({ file_name: popfilename });
  const [editLoader, setEditLoader] = useState(false);

  const [showPop, setShowPop] = useState(!Boolean(popfilename));

  const [dynProductFormData, setDynProductFormData] = useState(defaultDynFormParams);
  const { campaignid, mandate_pricepaid, mandate_proofofpurchase, mandate_retailername, mandate_retailerassociate } = dynProductFormData

  const [fileTypeError, setFileTypeError] = useState({});
  const { showFileTypeError, fileErrorMessage } = fileTypeError;

  const purchaseDate = purchasedate && convertDate(purchasedate);

  const locale = i18n?.resolvedLanguage;
  const { countryCode } = languageOptions[locale];

  useEffect(() => {
    const fetchRetailers = async () => {
      try {
        setShowBLoader(true);
        const getPurchaseType = selectedPurchaseType || purchasetype;
        const data = await getRetailerList(getPurchaseType, country);
        setRetailerOptions([...data]);
      } catch (error) {
        setRetailerOptions([]);
        // setShowAlert({ type: SHOW_ERROR_ALERT, message: t('alertMessages.retailerError') });
      } finally {
        setShowBLoader(false);
      }
    };
    if (Boolean(selectedPurchaseType)) {
      fetchRetailers();
    }
  }, [country, selectedPurchaseType]);

  useEffect(() => {
    const fetchDynFormData = async () => {
      try {
        setShowBLoader(true)
        const dynFormData = await getMandatoryFields(productid, countryCode, purchasedate, true, product_campaign_id)
        if (!isEmptyObj(dynFormData)) {
          setDynProductFormData(dynFormData)
        }
      } catch (error) {
        setShowAlert({ type: SHOW_ERROR_ALERT, message: error?.message || t('alertMessages.error') });
      } finally {
        setShowBLoader(false)
      }
    }
    fetchDynFormData()
  }, [])

  const onClose = (data) => {
    if (data !== undefined) {
      onSubmitEdit(data);
    }
    var form = document.getElementById("editProduct");
    form.classList.remove("was-validated");
    setUploadProof({});
    closeEditModal();
  };

  const handlePurchaseType = (type) => {
    setSelectedPurchaseType(type);
    resetField('retailAssociate');
    setValue('retailerName', '');
  }

  const updatePop = () => {
    setUploadProof({})
    setShowPop(true)
  }

  const removeProofRef = () => {
    popInputRef.current.value = "";
    setUploadProof({})
  }

  const onFileChange = async (event) => {
    event?.preventDefault();
    const file = event.target?.files[0];
    if (fileSizeRestriction(file)) {
      popInputRef.current.value = "";
      setFileTypeError({ showFileTypeError: true, fileErrorMessage: t("alertMessages.fileSizeExceeded") });
      return;
    }
    const isHeicFile = file?.name?.split('.')[file?.name?.split('.').length - 1] === 'heic'
    if (isHeicFile) {
      uploadHeicFile(file);
    } else {
      if (!checkFileType(file)) {
        popInputRef.current.value = "";
        setFileTypeError({ showFileTypeError: true, fileErrorMessage: t("alertMessages.fileError") });
        return
      } else {
        const payload = {
          file_name: file?.name,
          file_type: file?.type,
          file_size: file?.size,
          file: file
        };
        setUploadProof(payload);
        setFileTypeError({})
      }
    }
  };


  const uploadHeicFile = async (heicFileData) => {
    try {
      setShowBLoader(true);
      const heicFile = await convertHeicToJpeg(heicFileData)
      const payload = {
        file_name: heicFile?.name,
        file_type: heicFile?.type,
        file_size: heicFile?.size,
        file: heicFile,
      };
      setUploadProof(payload);
    } catch (error) {
      popInputRef.current.value = "";
      setShowAlert({ type: SHOW_ERROR_ALERT, message: error.message });
    } finally {
      setFileTypeError({})
      setShowBLoader(false);
    }
  }

  const convertHeicToJpeg = async (file) => {
    const jpegFile = await heic2any({
      blob: file,
      toType: 'image/jpeg',
      quality: 0.94,
    });
    jpegFile.lastModifiedDate = new Date();
    jpegFile.name = `${file?.name?.split('.')?.[0]}.jpg`;
    return jpegFile
  }

  const notValidData = (formData) => {
    const { purchaseDate, retailerName, deliveryDate } = formData
    let isNotValid = false;
    if (!Boolean(retailerName) || !Boolean(selectedPurchaseType)) {
      isNotValid = true
    }
    if (Boolean(mandate_proofofpurchase) && !Boolean(uploadProof?.file_name)) {
      isNotValid = true
    }
    if (new Date(purchaseDate) > new Date(deliveryDate)) {
      setError('deliveryDate', { type: 'required', message: t('validation.deliveryDtPast') })
      isNotValid = true
    }
    if (withoutTime(deliveryDate) > withoutTime(refFutureDate(purchaseDate))) {
      setError('deliveryDate', { type: 'required', message: t('validation.deliveryDtWrongYr') })
      isNotValid = true
    }
    return isNotValid
  }

  const onSubmitModal = (formData, event) => {
    const { file_name, file_type, file_size, file } = uploadProof;
    if (notValidData(formData)) {
      var form = document.getElementById("editProduct");
      if (!form.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      }
      form.classList.add("was-validated");
      return;
    }
    if (!Object.keys(touchedFields)?.length && !file_size) {
      onClose();
      return;
    }
    const popPath = formData?.purchaseDate.split('-')[0] + '-' + formData?.purchaseDate.split('-')[1];

    const popRegistrationId = registrationid;
    let editProduct = {
      product_id: productid,
      sku: skuname,
      purchase_date: formData?.purchaseDate,
      delivery_date: formData?.deliveryDate || null,
      price_paid: formData?.pricePaid || 0,
      retailer_id:
        isJSON(formData?.retailerName) === true
          ? JSON.parse(formData?.retailerName)?.retailer_id || null
          : retailerid,
      retailer_name: isJSON(formData?.retailerName) === true
        ? JSON.parse(formData?.retailerName)?.retailer_name || null
        : retailername,
      retail_associate: formData?.retailAssociate,
      purchaseType: selectedPurchaseType || purchasetype,
      serial_number: serialnumber,
      registrationid: registrationid,
      registriaid: registriaid,
      file_name: Boolean(file_size) ? file_name : null,
      file_path: Boolean(file_size) ? `${popPath}/${popRegistrationId}_${file_name}` : null,
      file_type: Boolean(file_size) ? file_type : null,
      file_size,
      isUpdate: true,
      ispopactive,
      campaign_id: campaignid
    };
    onEdit(editProduct, file);
  };

  const onEdit = async (productData, file) => {
    try {
      setEditLoader(true);
      const editData = await registerProductOfUser(productData);

      //capturing event for Google analytics
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "post_login_update",
        SKU: productData?.sku,
        purchaseDate: productData?.purchase_date,
        deliveryDate: productData?.delivery_date,
        pricePaid: productData?.price_paid,
        retailername: productData?.retailer_name,
      });

      if (editData?.uploadPopData) {
        const S3Url = editData?.uploadPopData?.split('&x-amz-security-token')[0];
        await axios({ method: "PUT", url: S3Url, data: file, headers: { 'Content-Type': file.type } });
      }

      if (Boolean(editData)) {
        onClose(productData);
        doExternalCalls(editData?.token)
      } else {
        onClose()
        handlePopNotification('ERROR', t("alertMessages.error"));
      }
    } catch (error) {
      onClose()
      handlePopNotification('ERROR', t("alertMessages.catch"));
    } finally {
      setEditLoader(false);
    }
  };

  const onError = (errors, event) => {
    var form = document.getElementById("editProduct");
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }
    form.classList.add("was-validated");
  };

  const mandatoryDeliveryDate = state_code === "CA"
  const showRetailAssociate = selectedPurchaseType === purchaseType.inStore && (Boolean(campaignid) && campaignid !== 'DEFAULT')

  return (
    <BackDropModal>
      <div
        className={`modal fade ${show ? "show" : ""}`}
        id="editProductDemo"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="editProductDemo"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body d-flex flex-column ps-7 pe-7">
              <i
                className="modal_close-button spri-icon-xmark-solid"
                style={{ color: "rgba(53, 51, 60, 0.5)", fontSize: "28px" }}
                data-bs-dismiss="modal"
                id="close_editmodal"
                onClick={() => onClose()}
              ></i>
              <h2 className="m-0 modal_title">{t('modalOptions.Product Editing')}</h2>

              <form
                className="collapse multi-collapse show"
                id="editProduct"
                noValidate
                autoComplete="off"
                onSubmit={handleSubmit(onSubmitModal, onError)}
              >
                <div className="mx-0 justify-content-center justify-content-md-start d-flex form-group-wrapper flex-wrap">
                  <div className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end">
                    <input
                      type="date"
                      className="form-control order-2"
                      id="purchaseDateEdited"
                      name="purchaseDate"
                      disabled
                      required
                      defaultValue={purchaseDate}
                      {...register("purchaseDate")}
                    />
                    <label
                      htmlFor="purchaseDate"
                      className="form-label order-2"
                    >
                      {t("productRegistration.Purchase Date *")}
                    </label>
                    <div className="invalid-feedback order-1">
                      {t("validation.purchaseDtReq")}
                    </div>
                  </div>
                  <div className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end">
                    <input
                      type="date"
                      className="form-control order-2"
                      id="deliveryDateEdited"
                      name="deliveryDate"
                      required={mandatoryDeliveryDate}
                      defaultValue={dateofdelivery ? convertDate(dateofdelivery) : ''}
                      disabled={!purchaseDate ? true : false}
                      min={purchaseDate && convertDate(new Date(purchaseDate))}
                      max={purchaseDate && convertDate(refFutureDate(purchaseDate))}
                      {...register("deliveryDate", {
                        required: mandatoryDeliveryDate && t('validation.deliveryDtReq'),
                      })}
                    />
                    <label
                      htmlFor="deliveryDate"
                      className="form-label order-2"
                    >
                      {mandatoryDeliveryDate ? t("productRegistration.Delivery Date *") : t("productRegistration.Delivery Date (optional)")}
                    </label>
                    {errors?.deliveryDate &&
                      <div className="invalid-feedback order-1">
                        {errors?.deliveryDate?.message}
                      </div>}
                  </div>
                  <div className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end">
                    <Controller
                      name="pricePaid"
                      control={control}
                      rules={{
                        required: Boolean(mandate_pricepaid) && t("validation.priceReq")
                      }}
                      render={({ field }) =>
                        <MaskedInput
                          {...field}
                          id="pricePaid"
                          name="pricePaid"
                          mask={currencyMask}
                          defaultValue={pricepaid}
                          className="form-control order-2"
                          required={Boolean(mandate_pricepaid)}
                        />}
                    />
                    <label
                      htmlFor="pricePaid"
                      className="form-label order-2"
                    >
                      {Boolean(mandate_pricepaid) ? t("productRegistration.Price Paid *") : t("productRegistration.Price Paid (optional)")}
                    </label>
                    {errors?.pricePaid &&
                      <div className="invalid-feedback order-1">
                        {errors?.pricePaid?.message}
                      </div>}
                  </div>
                  <div className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end">
                    {showPop
                      ? <input
                        ref={popInputRef}
                        type="file"
                        className="form-control order-2"
                        id="proofOfPurchaseEdited"
                        onChange={(e) => onFileChange(e)}
                        required={Boolean(mandate_proofofpurchase)}
                        onClick={() => removeProofRef()}
                        accept={"image/*,.heic,.heif,application/pdf"}
                      />
                      : <div className="d-flex">
                        <button className="update-pop-button" onClick={() => updatePop()}>{t('common.Update')}</button>
                        <input
                          name="existingProof"
                          type="text"
                          disabled
                          className="form-control order-2 update-pop-inp"
                          id="existingProof"
                          defaultValue={popfilename}
                        />
                      </div>}
                    <label
                      htmlFor="proofOfPurchase"
                      className="form-label order-2"
                    >
                      {Boolean(mandate_proofofpurchase) ? t("productRegistration.Proof of Purchase *") : t("productRegistration.Proof of Purchase (optional)")}
                    </label>
                    {showPop && <>
                      {(showFileTypeError) &&
                        <div className="error-field">
                          <div className="error-field-text">
                            {fileErrorMessage}
                          </div>
                        </div>}
                      {isEmptyObj(uploadProof) &&
                        <div className="invalid-feedback order-1">
                          {t('validation.popReq')}
                        </div>}
                    </>}
                  </div>
                  <div className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end">
                    <div
                      className="form-check order-2"
                      onChange={() => handlePurchaseType(purchaseType.online)}
                    >
                      <input
                        type="radio"
                        className="form-check-input"
                        id="purchasedOnlineEdited"
                        name="radio-stacked"
                        defaultChecked={selectedPurchaseType === purchaseType.online}
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="purchasedOnline"
                      >
                        {t("productRegistration.Purchased Online")}
                      </label>
                    </div>
                    <div
                      className="form-check mb-3 order-2"
                      onChange={() => handlePurchaseType(purchaseType.inStore)}
                    >
                      <input
                        type="radio"
                        className="form-check-input"
                        id="purchasedInStoreEdited"
                        name="radio-stacked"
                        defaultChecked={selectedPurchaseType === purchaseType.inStore}
                        required
                      />
                      <label
                        className="form-check-label"
                        htmlFor="purchasedInStore"
                      >
                        {t("productRegistration.Purchased In-store")}
                      </label>
                    </div>
                    <input
                      type="radio"
                      className="form-check-input d-none"
                      id="errorMessageTriggerEdited"
                      name="radio-stacked"
                      required
                    />
                    <div className="invalid-feedback order-1">
                      {t("validation.purchaseTypeReq")}
                    </div>
                    <label
                      htmlFor="errorMessageTriggerEdited"
                      className="form-label order-2"
                    >
                      {t("productRegistration.Did you purchase this online or in-store?")}&nbsp;*
                    </label>
                  </div>
                  <div className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end" />
                  <div
                    className="form-group px-0 d-flex flex-column-reverse justify-content-end"
                    style={{ width: "100%", maxWidth: 285 }}
                  >
                    <select
                      className="form-select order-2"
                      id="retailerName"
                      name="retailerName"
                      defaultValue={""}
                      required={Boolean(mandate_retailername)}
                      {...register("retailerName", {
                        required: Boolean(mandate_retailername) && t("validation.retailerNameReq")
                      })}
                    >
                      <option value={getValues("retailerName")}>{getValues("retailerName")}</option>
                      {retailerOptions.map((retailer) => (
                        <option
                          value={JSON.stringify(retailer)}
                          key={retailer?.retailer_id}
                        >
                          {retailer?.display_text}
                        </option>
                      ))}
                    </select>
                    <label
                      htmlFor="retailerName"
                      className="form-label order-2"
                    >
                      {Boolean(mandate_retailername) ? t("productRegistration.Retailer Name *") : t("productRegistration.Retailer Name (optional)")}
                    </label>
                    {errors?.retailerName &&
                      <div className="invalid-feedback order-1">
                        {errors?.retailerName?.message}
                      </div>}
                  </div>
                  <div className="form-group modal-form-group px-0 d-flex flex-column-reverse justify-content-end">
                    {showRetailAssociate &&
                      <>
                        <input
                          name="retailAssociate"
                          type="text"
                          className="form-control order-2"
                          id="retailAssociate"
                          defaultValue={retailassociate || ''}
                          placeholder={t("productRegistration.Retail Associate Placeholder")}
                          required={Boolean(mandate_retailerassociate)}
                          style={{ borderColor: errors?.retailAssociate && '#dc3545' }}
                          {...register("retailAssociate", {
                            required: Boolean(mandate_retailerassociate) && t("validation.retailAssociateReq"),
                            maxLength: {
                              value: 50,
                              message: t("validation.maxEmailLength")
                            }
                          })}
                        />
                        <label
                          htmlFor="retailAssociate"
                          className="form-label order-2"
                          style={{ color: errors?.retailAssociate && '#dc3545' }}
                        >
                          {Boolean(mandate_retailerassociate) ? t("productRegistration.Retail Associate *") : t("productRegistration.Retail Associate (optional)")}
                        </label>
                        {errors?.retailAssociate &&
                          <div className="error-field order-1">
                            <div className="error-field-text">
                              {errors?.retailAssociate?.message}
                            </div>
                          </div>}
                      </>}
                  </div>
                  {editLoader
                    ? <ModalButtonLoader />
                    : <div className="form-group d-flex px-0 w-100">
                      <button
                        type="submit"
                        className="btn btn-primary mb-4 mb-md-0 ms-auto me-md-0 me-auto"
                        aria-expanded="false"
                        aria-controls="editProduct"
                        style={{ width: "228px" }}
                      >
                        {t('common.Save')}
                      </button>
                    </div>}
                </div>
              </form>
            </div>
          </div>
          <BackdropLoader show={showBLoader} size={"medium"} type="modal" />
        </div>
      </div>
    </BackDropModal>
  );
};