import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router";
import { useLocation, useParams } from "react-router-dom";
import { API, redirect_uri } from "../../config";
import { AxiosInstancePrivate } from "../../utils/AxiosInstance";
import { Loading } from "./Loading";
import { codeExchange } from "../../service/ProductService"
import { AppContext } from "../../store/AppProvider";
import { UPDATE_LOGGED_IN_USER } from "../../store/reducer/LoggedInUserReducer";
import { setSessionStorage } from "../../service/SessionStorageService";
import { accessToken, refreshToken } from "../../utils/Constants";
import { UPDATE_STATE_CODE } from "../../store/reducer/StateReducer";

export const Callback = () => {
	const location = useLocation();
	const navigateTo = useNavigate();
	const { setLoggedInUser, setStateCode } = useContext(AppContext);
	const { locale } = useParams();

	useEffect(() => {
		const urlParams = new URLSearchParams(location.search);
		const authCode = urlParams.get("code");

		if (!!authCode) {
			const authCodeBody = {
				redirect_uri: redirect_uri,
				code: authCode,
				scope: "openid users",
			};

			// const refreshTokenBody = {
			// 	redirect_uri: redirect_uri,
			// 	refresh_token: authCode,
			// 	scope: "openid users",
			// };

			codeExchange(authCodeBody, authCode, locale)
				.then((response) => {
					// set the access token and refresh token in the session storage.
					setSessionStorage(accessToken, response?.access_token);
					setSessionStorage(refreshToken, response?.refresh_token);
					setLoggedInUser({
						type: UPDATE_LOGGED_IN_USER,
						payload: { first_name: response?.first_name },
					});
					setStateCode({
						type: UPDATE_STATE_CODE,
						payload: { state_code: response?.country_subdivision }
					})
					
				    // for buildSonyDataLayer
			        window.getUserInfo = {
                        name: `${response?.first_name?.trim()} ${response?.last_name?.trim()}`,
                        email: response?.verifiedEmail,
                        id: response?.id
                    }

					//Capturing event for Google analytics
					window.dataLayer = window.dataLayer || [];
					window.dataLayer.push({
						event: "login",
						loggedInUsername: response?.first_name,
					});
					navigateTo(locale ? `/${locale}/registered_products` : "/registered_products");
					// axios.get(`${API}api/authorize`).then((response) => {
					// 	navigateTo("/registered_products");
					// });
				})
				.catch((error) => {
					console.error("API Error:", error);
				});
		} else {
			locale ? navigateTo(`/${locale}/login`) : navigateTo("/login");
		}
		window.history.replaceState({}, document.title, window.location.pathname);
	}, [location]);

	return (
		<div>
			<Loading show={true} type="cb"></Loading>
		</div>
	);
};