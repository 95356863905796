import React, { useContext, useEffect, useRef, useState } from "react";
import { useForm, useWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowSvg } from "../../assets/img/Arrow.svg";
import { ReactComponent as InfoSvg } from "../../assets/img/info.svg";
import i18n from "../../i18n";
import { getProductList } from "../../service/ProductService";
import { AppContext } from "../../store/AppProvider";
import { SHOW_ERROR_ALERT } from "../../store/reducer/AlertReducer";
import { languageOptions, modelNumberLinks, refPastDate, zvModels } from "../../utils/dataConstants";
import { convertDate, isFutureDate, removeHtmlTags, withoutTime, refFutureDate } from "../../utils/utils";
import { AlphaModal } from "../../pages/myProducts/Modal/AlphaModal";

export const YourProductInformation = ({
  onBackButtonPress,
  userData,
  onButtonPress,
  show = true
}) => {
  const buttonRef = useRef(null);
  const modelRef = useRef(null);
  const { setShowAlert } = useContext(AppContext);

  const [modelInputValue, setModelInputValue] = useState("");
  const [required, setRequired] = useState(false);
  const [showModelOptions, setShowModelOptions] = useState(false);
  const [modelNumberError, setModelNumberError] = useState(false);
  const [fetchInProgress, setFetchInProgress] = useState(true);
  const [modelOptions, setModelOptions] = useState([]);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState("");

  const [showAUPopup, setShowAUPopup] = useState(false);
  const [skipAURegistration, setSkipAURegistration] = useState(false);

  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
    control,
  } = useForm({
    purchaseDate: '',
    deliveryDate: ''
  });

  const locale = i18n.resolvedLanguage;
  const { country } = languageOptions[locale];

  useEffect(() => {
    if (userData) {
      let postalCode = userData?.address?.zip;
      setRequired(postalCode >= "90001" && postalCode <= "96162");
    }
  }, [userData]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setFetchInProgress(true)
        const data = await getProductList(modelInputValue?.trim(), locale);
        setModelOptions([...data]);
      } catch (error) {
        setModelOptions([]);
        setShowAlert({ type: SHOW_ERROR_ALERT, message: t('alertMessages.productError') })
      } finally {
        setFetchInProgress(false)
      }
    };

    let timer;
    if (modelInputValue.length > 2) {
      timer = setTimeout(() => fetchProducts(), 250);
    }

    if (modelInputValue.trim() === "") {
      setShowModelOptions(false);
    }

    return () => {
      clearTimeout(timer);
    }
  }, [modelInputValue]);

  const selectPurchaseDate = useWatch({
    control,
    name: 'purchaseDate'
  });

  //reset delivery date
  useEffect(() => {
    !!selectPurchaseDate && setValue('dateOfDelivery', '');
  }, [selectPurchaseDate, setValue]);

  useEffect(() => {
    const currentParams = new URLSearchParams(window?.location?.search?.toLowerCase());
    const modelParamName = currentParams?.get("model");
    if (Boolean(modelParamName)) {
      handleModelInputChange(modelParamName);
      setTimeout(() => {
        const element = document?.getElementById('list-autocomplete')?.children;
        if (Array.from(element)?.length === 1) {
          buttonRef?.current?.click()
          modelRef.current.disabled = true
        } else {
          setModelInputValue('')
          setSelectedProductId(null);
          setSelectedProduct('');
          setShowModelOptions(false);
          modelRef.current.disabled = false
        }
      }, 5000);
    }
  }, []);

  const onPressModelOption = (displayText, productId) => {
    setModelInputValue(displayText);
    setSelectedProductId(productId);
    setSelectedProduct(displayText);
    setShowModelOptions(false);
  };

  const onBlurCalled = (value) => {
    if (selectedProduct?.length && selectedProduct !== value) {
      setModelInputValue('')
      setSelectedProductId(null);
      setSelectedProduct('');
      setShowModelOptions(false);
    }
  }

  const handleModelInputChange = (val) => {
    setShowModelOptions(true);
    setModelInputValue(val);
    //changes for error
    setModelNumberError(!val)
    if (!val) {
      setSelectedProductId(null)
    }
  };

  const onError = (errors, event) => {
    if (!selectedProductId) {
      //changes for error
      setModelNumberError(true);
    }
    var form = document.getElementById("multiCollapseExample2");
    if (!form.checkValidity()) {
      event.preventDefault();
      event.stopPropagation();
    }
    form.classList.add("was-validated");
  };

  const notValidData = (formData) => {
    const { purchaseDate, dateOfDelivery } = formData
    let isNotValid = false;
    if (isFutureDate(purchaseDate)) {
      setError('purchaseDate', { type: 'required', message: t('validation.purchaseDtFuture') })
      isNotValid = true
    }
    if (withoutTime(purchaseDate) < withoutTime(refPastDate)) {
      setError('purchaseDate', { type: 'required', message: t('validation.purchaseDtWrongYr') })
      isNotValid = true
    }
    if (new Date(purchaseDate) > new Date(dateOfDelivery)) {
      setError('dateOfDelivery', { type: 'required', message: t('validation.deliveryDtPast') })
      isNotValid = true
    }
    if (withoutTime(dateOfDelivery) > withoutTime(refFutureDate(purchaseDate))) {
      setError('dateOfDelivery', { type: 'required', message: t('validation.deliveryDtWrongYr') })
      isNotValid = true
    }
    if (!selectedProductId) {
      setModelNumberError(true);
      isNotValid = true
    }
    return isNotValid
  }

  const onSubmit = (formData, event) => {
    try {
      //changes for error
      if (notValidData(formData)) {
        var form = document.getElementById("multiCollapseExample2");
        if (!form.checkValidity()) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add("was-validated");
        return;
      }
      const yourProduct = {
        product_id: selectedProductId || "",
        sku: selectedProduct.split(" (")[0] || "",
        locale: locale,
        purchase_date: formData?.purchaseDate || null,
        delivery_date: formData?.dateOfDelivery || null,
      };
      if (country === 'us' && zvModels?.includes(yourProduct?.sku) && !skipAURegistration) {
        setShowAUPopup(true)
      } else {
        onButtonPress(yourProduct);
      }
    } catch (error) {
      setShowAlert({ type: SHOW_ERROR_ALERT, message: t('alertMessages.error') });
    }
  };

  const validationRules = {
    modelNumber: {
      required: t("validation.modelNumReq"),
    },
    purchaseDate: {
      required: t("validation.purchaseDtReq"),
    },
    purchaseType: {
      required: t("validation.purchaseTypeReq"),
    },
  };

  const mandatoryDeliveryDate = userData?.address?.zip >= "90001" && userData?.address?.zip <= "96162"
  //changes for error
  const errorStyle = {
    display: 'block',
    background: "rgb(220, 53, 69)",
    padding: "10px 15px",
    borderRadius: "6px",
    color: "#fff"
  }
  const { purchaseDate } = validationRules;
  const showOptionsBlock = (modelNumberError || !modelInputValue) ? 'none' : "block";
  const showNoMatchingResult = showModelOptions && !modelOptions?.length && modelInputValue?.length > 2 && !selectedProductId;

  return (
    <>
      <form
        className={`collapse multi-collapse ${show ? "show" : "d-none"} needs-validation`}
        id="multiCollapseExample2"
        onSubmit={handleSubmit(onSubmit, onError)}
        noValidate
        autoComplete="off"
      >
        <div className="d-grid justify-content-between flex-wrap form-group-wrapper margin-label">
          <div className="form-group px-0 d-flex flex-column-reverse justify-content-end">
            <a
              id="ModelHelp"
              className="form-text order-1"
              href={modelNumberLinks[`${country}ModelNumberLink`]}
              target="_blank"
            >
              <InfoSvg />&nbsp;
              {t(`productRegistration.Where's my model number?`)}
            </a>
            <div className="dropdown order-1" onBlur={({ target: { value } }) => onBlurCalled(value)}>
              <input
                ref={modelRef}
                type="text"
                id="modelNumber"
                className=" form-control"
                placeholder={t('productRegistration.modelPlaceholder')}
                name="modelNumber"
                value={modelInputValue}
                onChange={(e) => handleModelInputChange(e.target.value)}
                required
              />
              {showModelOptions &&
                <div
                  style={{
                    display: showOptionsBlock,
                    position: "absolute",
                    top: "100%",
                    left: "0",
                    zIndex: "1000",
                    width: "100%",
                    padding: "0.75px",
                    margin: "-1px 0 0 0",
                    fontSize: "14px",
                    color: "#333",
                    listStyle: "none",
                    backgroundColor: "#fff",
                    backgroundClip: "padding-box",
                    borderRadius: "0 0 3px 3px",
                    maxHeight: "350px",
                    overflowY: "auto",
                    boxShadow:
                      "2px 2px 4px rgba(0, 0, 0, .08), inset 0 0 0 0.6px #d2d2d2",
                  }}
                >
                  <div className="list-autocomplete" id="list-autocomplete">
                    {
                      selectedProductId && !modelOptions?.length ? (
                        <button
                          type="button"
                          style={{ padding: ".25rem 1rem .25rem 1rem", backgroundColor: '#CCC' }}
                          className="dropdown-item"
                          key={selectedProductId}
                          onMouseDown={(e) => e.preventDefault()}
                          onClick={() => onPressModelOption(selectedProduct, selectedProductId)}
                        >
                          {selectedProduct}
                        </button>
                      ) : (modelOptions?.map(({ display_text, product_id }) => {
                        const parsedText = removeHtmlTags(display_text);
                        return (
                          <button
                            type="button"
                            style={{ padding: ".25rem 1rem .25rem 1rem" }}
                            className="dropdown-item"
                            id="dropdown-item"
                            ref={buttonRef}
                            key={product_id}
                            onMouseDown={(e) => e.preventDefault()}
                            onClick={() =>
                              onPressModelOption(parsedText, product_id)
                            }
                          >
                            {parsedText}
                          </button>
                        );
                      })
                      )}
                  </div>
                </div>}
            </div>
            <label className="invalid-feedback form-label order-1" htmlFor="modelNumber" style={{ color: modelNumberError && '#dc3545' }}>
              {t("productRegistration.Model Number")} *
            </label>
            {!fetchInProgress && showNoMatchingResult &&
              <div
                style={{
                  display: showOptionsBlock,
                  marginTop: '0.25rem',
                  fontSize: '.875em',
                  background: 'rgb(220, 53, 69)',
                  borderRadius: 6,
                  padding: 0.75
                }}>
                <i className="hasNoResults">{t('productRegistration.No matching results')}</i>
              </div>
            }
            <div className="invalid-feedback" style={modelNumberError ? errorStyle : null}>
              {t("validation.modelNumReq")}
            </div>
          </div>

          <div className="form-group d-md-flex" />
          <div className="form-group px-0 d-flex flex-column-reverse justify-content-end">
            <input
              id="startDate"
              className="form-control order-2"
              type="date"
              required
              name="purchaseDate"
              max={convertDate(new Date())}
              min={convertDate(refPastDate)}
              {...register("purchaseDate", purchaseDate)}
            />
            <label
              htmlFor="startDate"
              className="invalid-feedback form-label order-2"
            >
              {t("productRegistration.Purchase Date *")}
            </label>
            {errors?.purchaseDate && <div className="invalid-feedback order-1">
              {errors?.purchaseDate?.message}
            </div>}
          </div>
          <div className="form-group px-0 d-flex flex-column-reverse justify-content-end">
            <input
              id="DeliveryDate"
              className="form-control order-2"
              type="date"
              required={mandatoryDeliveryDate}
              name="dateOfDelivery"
              disabled={!selectPurchaseDate}
              min={selectPurchaseDate && convertDate(new Date(selectPurchaseDate))}
              max={selectPurchaseDate && convertDate(refFutureDate(selectPurchaseDate))}
              {...register("dateOfDelivery", {
                required: mandatoryDeliveryDate && t('validation.deliveryDtReq'),
              })}
            />
            <label
              htmlFor="DeliveryDate"
              className="invalid-feedback form-label order-2"
            >
              {mandatoryDeliveryDate ? t("productRegistration.Delivery Date *") : t("productRegistration.Delivery Date (optional)")}
            </label>
            {errors?.dateOfDelivery && <div className="invalid-feedback order-1">
              {errors?.dateOfDelivery?.message}
            </div>}
          </div>

          <div
            id="return"
            className="row d-flex justify-content-between submit-block form-group__2span"
          >
            <div className="d-flex justify-content-between p-0">
              <ArrowSvg />
              <button
                type="button"
                className="return"
                aria-expanded="false"
                aria-controls="multiCollapseExample1 multiCollapseExample2 multiCollapse1 multiCollapse2"
                onClick={() => onBackButtonPress()}
              >
                {t("productRegistration.Return")}
              </button>
            </div>
            <button id="submit" type="submit" className="login Submit"> {t('productRegistration.Continue')}</button>
          </div>
        </div>
      </form >
      <AlphaModal
        show={showAUPopup}
        onClose={() => {
          setShowAUPopup(false);
          setSkipAURegistration(true)
        }} />
    </>
  );
};