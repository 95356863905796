import { useTranslation } from "react-i18next";
import { handleLogout } from "../../utils/utils";
import { BackDropModal } from "../CustomModal/backdropModal";

export const LogoutModal = ({ closeLogOut, show, locale }) => {
  const { t } = useTranslation();
  const onLogOut = () => {
    handleLogout(locale, false);
    closeLogOut();
  };
  return show ? (
    <BackDropModal>
      <div
        className={`modal fade ${show ? "show" : ""}`}
        id="logoutDemo"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="logoutDemo"
        aria-hidden="true"
        style={{ display: "block" }}
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-body d-flex flex-column ps-7 pe-7">
              <i
                className="modal_close-button spri-icon-xmark-solid"
                style={{ color: "rgba(53, 51, 60, 0.5)", fontSize: "28px" }}
                data-bs-dismiss="modal"
                onClick={() => closeLogOut()}
              ></i>
              <h2 className="m-0 modal_title">{t("logout.confirm")}</h2>
              <p className="modal_text">{t("logout.message")}</p>
              <div
                className="d-flex flex-md-row flex-column"
                style={{ gap: "20px" }}
              >
                <button className="btn btn-primary" onClick={() => onLogOut()}>
                  {t("logout.logout")}
                </button>
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => closeLogOut()}
                >
                  {t("logout.cancel")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BackDropModal>
  ) : (
    ""
  );
};