import axios from "axios";
import i18n from "../i18n";
import { API, redirect_uri } from "../config";
import { getSessionStorage, setSessionStorage } from "../service/SessionStorageService";
import { accessToken, refreshToken, scopeValue, xhrCall } from "./Constants";
import { handleLogout } from "./utils";

const convertLangToParams = (lang) => {
    return lang ? lang.toLowerCase().replace("_", '-') : null;
};

const AxiosInstancePrivate = () => {
    // const locale  = useParams()
    const locale = convertLangToParams(i18n.resolvedLanguage);
    const axiosIns = axios.create({
        baseURL: API,
        withCredentials: true
    });

    axiosIns.interceptors.request.use(
        (config) => {
            config.headers["pr-auth-token"] = getSessionStorage(accessToken);
            // Reset the time tracker for every xhr call.
            const restIdleTimeTracker = new CustomEvent(xhrCall, { detail: config.url });
            window.dispatchEvent(restIdleTimeTracker);

            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );

    axiosIns.interceptors.response.use(
        (res) => {
            return res;
        },
        async function (error) {
            const originalRequest = error.config;
            let refreshTokenError, res;
            if (originalRequest?._retry === undefined) {
                originalRequest['_retry'] = 0
            }
            // Set the retry logic at the  max for three times and terminate.
            if ((error?.response?.status === 400 || error?.code == 'ERR_NETWORK') && !originalRequest?.url?.includes('token') && originalRequest?._retry < 3) {
                originalRequest['_retry'] += 1;
                const refreshTokenBody = {
                    redirect_uri: redirect_uri,
                    refresh_token: getSessionStorage(refreshToken),
                    scope: scopeValue,
                };

                const accessTokenUrl = `${API}/oauth2/token`;

                [refreshTokenError, res] = await axiosIns.post(accessTokenUrl, refreshTokenBody, {
                    headers: {
                        refresh_token: getSessionStorage(refreshToken),
                    },
                })
                    .then((response) => {
                        // set the access token and refresh token in the session storage.
                        setSessionStorage(accessToken, response?.data?.access_token);
                        setSessionStorage(refreshToken, response?.data?.refresh_token);

                        // reset the new token.
                        originalRequest.headers["pr-auth-token"] = response?.data?.access_token;
                        const res = async () => await axios.request(originalRequest);
                        return [null, res()];
                    }).catch(function (error) {

                        if (error.response) {
                            // Request made and server responded
                            console.log(error.response.data);
                        } else if (error.request) {
                            // The request was made but no response was received
                            console.log(error.request);
                        } else {
                            // Something happened in setting up the request that triggered an Error, 404 not found
                            console.log('Error', error.message);
                        }
                    });
                if (refreshTokenError) {
                    return Promise.reject(refreshTokenError);
                }
                return Promise.resolve(res)
            }
            else if (error?.response?.status === 403 || (error?.response?.status === 400 && originalRequest?.url?.includes('token'))) {
                // Handle logout.
                await handleLogout(locale, true);
                return;
            }
            return Promise.reject(error)

        }
    );

    return axiosIns;
};

const AxiosInstancePublic = () => {
    const axiosIns = axios.create({
        baseURL: API,
        withCredentials: true
    });

    return axiosIns;
}

export { AxiosInstancePrivate, AxiosInstancePublic };
