import { BackDropModal } from "../../../components/CustomModal/backdropModal";
import zvBackground from "../../../assets/img/zv/background.jpg";
import zvAvatar from "../../../assets/img/zv/avatar.png";
import SonyLogo from "../../../appSVG/sonyLogo.png"
import './AlphaModal.css'
import { zvUniverse } from "../../../config";
import { zvModels } from "../../../utils/dataConstants";

export const AlphaModal = ({ show, onClose = () => { } }) => {

  return (
    <>
      {
        show && (
          <BackDropModal>
            <div
              className="modal fade show"
              id="productDeleteDemo"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="productDeleteDemo"
              data-bs-backdrop="false"
              aria-hidden="true"
              style={{ display: "block" }}
            >
              <div className="modal-dialog d-flex justify-content-center modal-dialog-centered" role="document">
                <div className="modal-content" style={{ width: 450, borderRadius: 5 }}>
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-center img-container">
                      <img
                        onClick={() => window.location.replace(zvUniverse)}
                        className="zv-img"
                        src={zvBackground} />
                      <img src={SonyLogo} className="sonyLogo logo-pos" alt="Sony Logo" />
                      <div className="main-text">
                        <div className="header-text">
                          <div>Free Peak Design Cuff</div>
                          <div>+</div>
                          <div>1 year additional</div>
                          <div>manufacturer warranty</div>
                        </div>
                        <div className="header-text2">WITH THE PURCHASE OF ANY ZV SERIES CAMERA*</div>
                        <img src={zvAvatar} className="zv-avatar" alt="ZV" />
                        <div className="d-flex mt-3 flex-column justify-content-center align-items-center">
                          <button
                            type="button"
                            className="btn btn-outline-secondary mb-2 zv-btn1"
                            onClick={() => window.location.replace(zvUniverse)}
                          >
                            I'D LOVE A BONUS CUFF
                          </button>
                          <button
                            type="button"
                            className="btn btn-primary zv-btn2"
                            onClick={() => onClose()}
                          >
                            NO, THANK YOU
                          </button>
                        </div>
                        <div className="d-flex terms flex-column justify-content-center align-items-center">
                          <p>
                            Between 10/01/24 and 1/31/25,buy an Eligible Product&nbsp;
                            (<span className="zv-tooltip zv-list">see list
                              <span className="tooltiptext">
                                {zvModels?.map((ele, index) => {
                                  if (index % 4 === 0) {
                                    return <span key={index}>{ele}<br /></span>
                                  } else {
                                    return <span key={index}>{ele},&nbsp;</span>
                                  }
                                })}
                              </span>
                            </span>) and get a free Peak Design Camera Cuff (color to be determined based on availability) and 1 Additional Year on the Limited Warranty accompanying the product, subject to the Terms and Conditions. Redeem no later than February 28, 2025 at
                            <span>
                              <a
                                href={zvUniverse}
                                target="_blank"
                              >
                                &nbsp;{zvUniverse}&nbsp;
                              </a>
                            </span>by completing the product registration and upload: (i) A copy of the purchase receipt for the Eligible product that includes the Sony Authorized Retailer's name and address. The purchase date and purchased model(s); and (ii) serial number of Eligible Product. See complete Terms and Conditions for details.
                          </p>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BackDropModal>
        )
      }
    </>
  );
}