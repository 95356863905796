import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"

export const PageNotFound = () => {
    const { t } = useTranslation();
    return (
        <div style={{ margin: '25px' }}>
            <h1>{t('alertMessages.pageNotFound')}</h1>
            <h4> {t('alertMessages.pageNotFound Message')}</h4>
            <h4>{t('alertMessages.redirect')} <Link to="/login"> {t('login.Login')}</Link></h4>
        </div>
    )
}